import { EmailInvitationDto, BulkInvitee } from 'ApiModels';
import { AxiosError, AxiosPromise, AxiosResponse } from 'axios';
import { concat, contains, filter, map, project } from 'ramda';
import {
  CalendarEvent,
  Circle,
  Community,
  CommunityDiscussions,
  CommunityMembers,
  CommunityProgressOverview,
  CommunityProjectsFilterAnswer,
  CommunityProjectsFilters,
  CommunityRequests,
  CommunityResources,
  Country,
  EventAttendee,
  Filtering,
  Interest,
  KpiDefinition,
  Member,
  MentionUser,
  Methodology,
  Project,
  ProjectAwaitingFeedback,
  ProjectCategory,
  ProjectNextParagraphs,
  ProjectStage,
  Report,
  ReportDefinition,
  Request,
  RequestFilteringSkill,
  RequestStatus,
  RequestStatusFilters,
  Resource,
  SDG,
  Skill,
  Sorting,
  Tag,
  TagType,
  CommunityMembersFilters,
  CommunityRequestsFilters,
  KPIList,
} from 'redux/types/account';
import {
  buildHeaders,
  getAxiosInstance,
  getDevelopmentStateList,
  getMonths,
  getResourceType,
  getSDGList,
  getTemplateReports,
} from './helper';

import {
  GlobalAllOptions,
  ImageType,
  Planner,
  PrivacyCircleType,
  Response,
  StatsType,
  RequestType,
} from '../../redux/types/enums';
import { sanitizeAssetUrl } from '../../util/assets';
import { uploadFile } from './file-upload';
import { removeProject, saveRequestComment } from './project';

import moment from 'moment';
import { CSVTable } from '../../routes/community/settings/statistics';
import { mentionParse } from '../../util/mention';
import { customSortArrayOfObjects, isNoFiltering, sortCountriesFilter } from '../../util/utils';

const ai = getAxiosInstance();

export function fetchCommunities(bearer: string): Promise<Community[]> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/User/GetMyCommunities',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then((response: AxiosResponse) => {
        const { data } = response;
        const resp: Community[] = map(
          (community): Community => ({
            id: community.id,
            circleName: community.circleName,
            reports: [],
            allowTasks: false,
            logo: sanitizeAssetUrl(community.logoImage),
            name: community.name,
            shortDescription: community.shortDescription,
            coverImage: sanitizeAssetUrl(community.mainImage),
            city: community.city,
            projectsCount: 0,
            developmentStageType: community.developmentStageType,
            methodologies: [],
          }),
          data,
        );
        resolve(resp);
      })
      .catch(err => reject(err));
  });
}

export function fetchCommunityDetails(
  communityId: number,
  bearer: string,
  fetchForTabRenamings?: boolean,
  silentCall?: boolean,
): Promise<Community> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/Community/GetOverview',
      params: {
        id: communityId,
      },
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then((response: AxiosResponse) => {
        const { data } = response;
        resolve({
          reports: [],
          id: Number(data.id),
          name: data.name,
          description: data.description,
          coverImage: sanitizeAssetUrl(data.mainImage),
          logo: sanitizeAssetUrl(data.logoImage),
          city: data.city,
          communityTags: data.communityTags,
          countryId: data.countryId,
          generalSettingsHasLockTags: data.generalSettingsHasLockTags,
          hideParagraphsUntilDate: data.hideParagraphsUntilDate,
          defaultProjectFilterTag: data.defaultProjectFilterTag,
          menuLogoImage: sanitizeAssetUrl(data.menuLogoImage),
          shortDescription: data.shortDescription,
          canEditCommunityInfo: data.canEditCommunityInfo,
          canSeeProjectOverview: data.canSeeProjectOverview,
          allowTasks: data.allowTasks,
          projectsCount: data.projectCount,
          canInviteToProjects: data.permissionCanInviteToProjects,
          canApproveParagraph:
            typeof data.canApproveParagraph === 'boolean'
              ? data.canApproveParagraph
              : data.permissionCanApproveParagraph,
          canEditProjectTags: data.canEditProjectTags,
          isMember: data.isMember,
          developmentStageType: data.developmentStage,
          projectsTabLiteral: data.projectsTabLiteral || '',
          businessModelTabLiteral: data.businessModelTabLiteral || '',
          isHomeTabVisible: data.isHomeTabVisible === null ? true : data.isHomeTabVisible,
          isProjectsTabVisible: data.isProjectsTabVisible === null ? true : data.isProjectsTabVisible,
          isForumTabVisible: data.isForumTabVisible === null ? true : data.isForumTabVisible,
          isRequestsTabVisible: data.isRequestsTabVisible === null ? true : data.isRequestsTabVisible,
          isMembersTabVisible: data.isMembersTabVisible === null ? true : data.isMembersTabVisible,
          isEventsTabVisible: data.isEventsTabVisible === null ? true : data.isEventsTabVisible,
          isAboutTabVisible: data.isAboutTabVisible === null ? true : data.isAboutTabVisible,
          isResourcesTabVisible: data.isResourcesTabVisible === null ? true : data.isResourcesTabVisible,
          isApplicationsTabVisible: data.isApplicationsTabVisible === null ? true : data.isApplicationsTabVisible,
          canViewApplicationsTab: data.canViewApplicationsTab,
          isSDGsVisible: data.isSDGsVisible,
          isUserSDGsVisible: data.isUserSDGsVisible,
          isWebSiteUrlVisible: data.isWebSiteUrlVisible,
          isFacebookUrlVisible: data.isFacebookUrlVisible,
          isLinkedinUrlVisible: data.isLinkedinUrlVisible,
          isDevelopmentStageVisible: data.isDevelopmentStageVisible,
          permissions: data.permissions,
          isCalendarSynchronized: data.isCalendarSynchronized,
          synchronizedCalendarAccount: data.synchronizedCalendarAccount,
          synchronizedCalendarType: data.synchronizedCalendarType,
          hasMeetingProvider: data.hasMeetingProvider,
          meetingProviderAccount: data.meetingProviderAccount,
          meetingProviderType: data.meetingProviderType,
          hasLandingPage: data.hasLandingPage,
          communitySkillCategories: data.communitySkillCategories.map((skill: any) => ({
            id: skill.id,
            skillCategoryId: skill.skillCategoryId,
            communityId: skill.communityId,
            name: skill.name,
            isActive: skill.isVisible,
          })),
          methodologies: data.methodologies.map(
            (methodology: any): Methodology => ({
              id: methodology.id,
              name: methodology.name,
              description: methodology.description,
              videoUrl: sanitizeAssetUrl(methodology.urlVideo),
              sections: [],
              planner: {
                tasks: [],
                plannerType: Planner.Cal,
              },
              isActive: methodology.isActive,
              isDeleted: methodology.isDeleted,
            }),
          ),
        });
      })
      .catch(err => {
        if (fetchForTabRenamings || silentCall) return reject(new Error('hideErrorToast'));
        return reject(err);
      });
  });
}

export function fetchCommunitySignupDetails(communityId: number): Promise<Community> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/Community/GetAbout',
      params: {
        id: communityId,
      },
    })
      .then((response: AxiosResponse) => {
        const { data } = response;
        resolve({
          reports: [],
          id: Number(data.id),
          name: data.name,
          allowTasks: false,
          shortDescription: data.shortDescription,
          logo: sanitizeAssetUrl(data.logoImage),
          coverImage: sanitizeAssetUrl(data.mainImage),
          city: data.city,
          communityTags: data.communityTags,
          countryId: data.countryId,
          generalSettingsHasLockTags: data.generalSettingsHasLockTags,
          hideParagraphsUntilDate: data.hideParagraphsUntilDate,
          defaultProjectFilterTag: data.defaultProjectFilterTag,
          menuLogoImage: sanitizeAssetUrl(data.menuLogoImage),
          isUserSDGsVisible: data.isUserSDGsVisible,
          termsAndConditionLink: data?.termsAndConditionLink || '',
        });
      })
      .catch(err => reject(err));
  });
}

export function fetchResourcesTags(
  communityId: number,
  bearer: string,
): Promise<{ resourcesTags: string[]; communityId: number }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/Tag/GetCommunityResourceTagNameList',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      params: {
        communityId,
      },
    })
      .then((response: AxiosResponse) => {
        const { data } = response;
        const res = {
          resourcesTags: data,
          communityId,
        };
        resolve(res);
      })
      .catch(err => reject(err));
  });
}

export function fetchCommunityCircleToCirclePermission(
  communityId: number,
  bearer: string,
): Promise<{ communityId: number; list: [] }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: `/api/CircleToCirclePermission/ByCommunity?communityId=${communityId}`,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then((response: AxiosResponse) => {
        const { data } = response;
        resolve({
          list: data,
          communityId,
        });
      })
      .catch(err => reject(err));
  });
}

export function fetchCommunityCircleToCirclePermissionSave(payload: any, bearer: string): Promise<any> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'POST',
      url: '/api/CircleToCirclePermission/SaveCommunityCircles',
      data: { ...payload },
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then(() => {
        resolve(payload);
      })
      .catch(err => reject(err));
  });
}

export function fetchGetPermissionsForUser(userId: number, bearer: string): Promise<any> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: `/api/CircleToCirclePermission/GetPermissionsForUser?userId=${userId}`,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then((response: AxiosResponse) => {
        const { data } = response;
        resolve(data);
      })
      .catch(err => reject(err));
  });
}

export function fetchCommunityResources(
  bearer: string,
  communityId: number,
  circles: Circle[],
  sorting: Sorting,
  filtering: Filtering,
  skip: number,
  take: number,
): Promise<{ list: CommunityResources; skip: number; take: number }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'POST',
      url: '/api/Community/GetResourceList',
      data: {
        communityId,
        ...sorting,
        ...filtering,
        skip,
        take,
      },
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then((response: AxiosResponse) => {
        const { data } = response;
        resolve({
          take,
          skip,
          list: {
            totalResourcesCount: isNoFiltering(filtering) ? data.resources.count : undefined,
            resourcesCount: skip === 0 ? data.resources.count : undefined,
            id: communityId,
            canEditResources: data.canEditResources,
            resources: map((r): Resource => {
              return {
                id: Number(r.id),
                name: r.name,
                icon: r.icon,
                tags: r.resourceTags,
                image: sanitizeAssetUrl(r.image),
                lastUpdated: r.lastUpdated,
                postDate: r.postDate,
                description: r.description,
                resourceUrl: sanitizeAssetUrl(r.resourceUrl),
                extension: getResourceType(r.resourceUrl),
                privacyLevel: r.privacyLevel,
                resourceType: r.resourceType,
                circles: r.resourcePrivacyCircles
                  ? map(c1 => {
                      return {
                        id: c1.communityCircleId,
                        name: filter((c2: Circle) => c2.id === c1.circleId, circles)[0]?.name,
                        admin: filter((c2: Circle) => c2.id === c1.circleId, circles)[0]?.admin,
                        shortName: filter((c2: Circle) => c2.id === c1.circleId, circles)[0]?.shortName,
                      };
                    }, r.resourcePrivacyCircles)
                  : [],
                communityResourceCircleTags: r.communityResourceCircleTags.map((tag: any) => tag.name) || [],
              };
            }, data.resources.list),
          },
        });
      })
      .catch(err => reject(err));
  });
}

function createCustomPrivacy(
  bearer: string,
  resource: Resource,
  entityId: number,
  isCommunityResource: boolean,
): Promise<boolean> {
  return new Promise((resolve, reject) => {
    let url: string;
    const data: any = {
      resourceId: resource.id,
      privacyLevel: resource.privacyLevel,
      privacyCircles: map((c: Circle) => {
        const cAux: any = {
          circleId: c.id,
        };
        if (isCommunityResource) {
          cAux.communityId = entityId;
        } else {
          cAux.projectId = entityId;
        }
        return cAux;
      }, resource.circles),
    };

    if (isCommunityResource) {
      data.communityId = entityId;
      url = '/api/Privacy/SaveCommunityResourcePrivacy';
    } else {
      data.projectId = entityId;
      url = '/api/Privacy/SaveProjectResourcePrivacy';
    }
    ai({
      method: 'POST',
      url,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      data,
    })
      .then(() => {
        resolve(true);
      })
      .catch(err => reject(err));
  });
}

export function createResource(
  resource: Resource,
  bearer: string,
  entityId: number,
  paragraphId: number | undefined,
  file: File | string,
  isCommunityResource: boolean,
  iconFile: File | string,
): Promise<{ resource: Resource; entityId: number; isCommunityResource: boolean }> {
  return new Promise((resolve, reject) => {
    let url: string = '';
    const data: any = {
      id: resource.id,
      resourceType: resource.resourceType,
      privacy: resource.privacyLevel,
      resourceTags: resource.tags,
      privacyLevel: resource.privacyLevel,
      name: resource.name,
      description: resource.description,
      postDate: resource.postDate,
      lastUpdated: resource.lastUpdated,
    };
    if (isCommunityResource) {
      data.communityId = entityId;
      data.circleTags = resource.communityResourceCircleTags;
      url = '/api/Resource/SaveCommunityResource';
    } else {
      data.projectId = entityId;
      data.paragraphId = paragraphId;
      url = '/api/Resource/SaveProjectResource';
    }
    data.resourceUrl = resource.resourceUrl;
    const promiseList = [];
    if (file) promiseList.push(uploadFile(bearer, { file: file, folder: 'resource_files' }, undefined, true));
    if (iconFile) promiseList?.push(uploadFile(bearer, { file: iconFile, folder: 'resource_files' }));
    Promise.all(promiseList)
      .then(responses => {
        if (responses.length === 1) {
          data[file ? 'resourceUrl' : 'icon'] = responses[0].link;
        } else if (responses.length > 1) {
          data.resourceUrl = responses[0].link;
          data.icon = responses[1]?.link;
        }
        ai({
          method: 'POST',
          url,
          headers: {
            Authorization: `Bearer ${bearer}`,
          },
          data,
        })
          .then((response: AxiosResponse) => {
            const { data } = response;
            const newResource: Resource = {
              id: data.id,
              tags: map(t => t.name, data.tags).reverse(),
              image: sanitizeAssetUrl(data.image),
              lastUpdated: data.lastUpdated,
              postDate: data.postDate,
              name: data.name,
              description: data.description,
              resourceUrl: sanitizeAssetUrl(data.resourceUrl),
              icon: sanitizeAssetUrl(data.icon),
              extension: getResourceType(data.resourceUrl),
              privacyLevel: data.privacyLevel,
              resourceType: data.resourceType,
              circles: resource.circles,
              paragraphId: data.paragraphId,
            };
            if (isCommunityResource) {
              newResource.communityId = entityId;
              newResource.communityResourceCircleTags = (data.communityResourceCircleTags || [])?.map(
                (tag: any) => tag.name,
              );
            }
            if (resource.circles.length > 0) {
              createCustomPrivacy(bearer, newResource, entityId, isCommunityResource).then(() => {
                resolve({ resource: newResource, entityId, isCommunityResource });
              });
            } else {
              resolve({ resource: newResource, entityId, isCommunityResource });
            }
          })
          .catch(err => reject(err));
      })
      .catch(err => {
        reject(err);
      });
  });
}

export function createResources(
  resources: Resource[],
  bearer: string,
  entityId: number,
  paragraphId: number | undefined,
  files: (File | string)[],
  isCommunityResource: boolean,
  icons: (File | string)[],
): Promise<{ resources: Resource[]; entityId: number; isCommunityResource: boolean }> {
  return new Promise((resolve, reject) => {
    if (files.length !== resources.length) reject(new Error('Files and resources dont have the same length'));
    Promise.all(
      resources.map((r: Resource, idx: number) =>
        createResource(r, bearer, entityId, paragraphId, files[idx], isCommunityResource, icons[idx]),
      ),
    ).then(response => {
      resolve({
        entityId,
        isCommunityResource,
        resources: response.map((r: { resource: Resource }) => r.resource),
      });
    });
  });
}

export function deleteResource(
  resourceId: number,
  entityId: number,
  bearer: string,
  isCommunityResource: boolean,
): Promise<{ resourceId: number; entityId: number; isCommunityResource: boolean }> {
  return new Promise((resolve, reject) => {
    let url: string;
    if (isCommunityResource) {
      url = '/api/Resource/RemoveCommunityResource';
    } else {
      url = '/api/Resource/RemoveProjectResource';
    }
    ai({
      method: 'GET',
      url,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      params: {
        id: resourceId,
      },
    })
      .then(() => {
        resolve({
          resourceId,
          entityId,
          isCommunityResource,
        });
      })
      .catch(err => reject(err));
  });
}

export function deleteResources(
  resourceIds: number[],
  entityId: number,
  bearer: string,
  isCommunityResource: boolean,
): Promise<{ resourceIds: number[]; entityId: number; isCommunityResource: boolean }> {
  return new Promise((resolve, reject) => {
    Promise.all(
      resourceIds.map((resourceId: number) => deleteResource(resourceId, entityId, bearer, isCommunityResource)),
    ).then(response => {
      resolve({
        resourceIds: response.map((response: { resourceId: number }) => response.resourceId),
        entityId,
        isCommunityResource,
      });
    });
  });
}

export function fetchAllCommunityRequests(
  communityId: number,
  bearer: string,
): Promise<{ list: RequestStatus[]; communityId: number }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: `/api/Admin/community/${communityId}/requests`,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then((response: AxiosResponse) => {
        const { data } = response;
        resolve({
          communityId,
          list: data.map((r: any) => {
            return {
              id: r.id || '',
              postDate: r.postDate || '',
              projectName: r.projectName,
              entrepreneurName: r.entrepreneurName || '',
              advisorName: r.advisorName || '',
              advisorComment: r.advisorComment || '',
              advisorNote: r.advisorNote || '',
              advisorId: '' + (r.advisorId || 0),
              advisorFeedbackDatetime: r.advisorFeedbackDatetime || '',
              advisorInvitedDateTime: r.advisorInvitedDateTime || '',
              advisorAssignedDateTime: r.advisorAssignedDateTime || '',
              entrepreneurComment: r.entrepreneurComment || '',
              entrepreneurFeedbackDatetime: r.entrepreneurFeedbackDatetime || '',
              eventDateTimeStart: r.eventDateTimeStart || '',
              eventId: r.eventId || '',
              skills: r.skills || '',
              requestStatus: r.requestStatus,
              advisorRating: (r.advisorRating !== null && `${r.advisorRating}/5`) || 'N/A',
              entrepreneurRating: (r.entrepreneurRating !== null && `${r.entrepreneurRating}/5`) || 'N/A',
              resolvedDate: moment(r.resolvedDate).format('YYYY-MM-DD') || '',
              projectId: r.projectId || '',
              name: r.name || 'Request page',
              sessionType: r.sessionType || '',
            };
          }),
        });
      })
      .catch(err => reject(err));
  });
}

export function downloadCommunityRequests(communityId: number, bearer: string): Promise<string> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: `/api/Admin/community/${communityId}/requests-csv`,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then((response: AxiosResponse) => {
        resolve(response.data);
      })
      .catch(err => reject(err));
  });
}

export function fetchCommunityFilteringSkills(
  bearer: string,
  communityId: number,
): Promise<{
  communityId: number;
  skills: RequestFilteringSkill[];
}> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: `/api/SkillCategory/GetByCommunityRequests`,
      params: {
        communityId,
      },
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then(response => {
        resolve({
          communityId,
          skills: response.data.sort(
            (skill1: RequestFilteringSkill, skill2: RequestFilteringSkill) => skill1.id - skill2.id,
          ),
        });
      })
      .catch(err => reject(err));
  });
}

// UNUSED ??
export function fetchRequestFilteringOptions(communityId: number, bearer: string): Promise<RequestStatusFilters> {
  return new Promise((resolve, reject) => {
    const filtersUrls = [
      `/api/Admin/community/${communityId}/request-advisors`,
      `/api/Admin/community/${communityId}/request-startups`,
      `/api/Admin/community/${communityId}/request-skills`,
    ];

    const filtersPromises = filtersUrls.map(f => {
      return ai({
        method: 'GET',
        url: f,
        headers: {
          Authorization: `Bearer ${bearer}`,
        },
      });
    });

    Promise.all(filtersPromises)
      .then(results => {
        resolve({
          advisors: results[0].data || [],
          startups: results[1].data || [],
          skills: results[2].data || [],
        });
      })
      .catch(err => {
        reject(err);
      });
  });
}

export function fetchCommunityRequests(
  communityId: number,
  bearer: string,
  sorting: Sorting,
  filtering: Filtering,
  skip: number,
  take: number,
): Promise<{
  list: CommunityRequests;
  skip: number;
  take: number;
  count: number;
  requestFilters: CommunityRequestsFilters;
}> {
  return new Promise((resolve, reject) => {
    const data = {
      ...filtering,
      ...sorting,
      skip,
      take,
      requestType: RequestType.ClassicRequest,
    };
    if (sorting.orderBy) data.orderBy = sorting.orderBy - 1;
    ai({
      method: 'POST',
      url: `/api/Community/${communityId}/requests`,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      data: data,
    })
      .then((response: AxiosResponse) => {
        const { data } = response;
        resolve({
          skip,
          take,
          count: data.count,
          list: {
            id: communityId,
            totalRequestsCount: isNoFiltering(filtering) ? data.count : undefined,
            requestsCount: skip === 0 ? data.count : undefined,
            canCreateRequests: data.canCreateRequests,
            openRequests: map((r: any): Request => {
              return {
                ...r,
                id: r.id,
                name: r.name,
                description: r.description,
                userId: r.userId,
                userFirstName: r.userFirstName,
                userLastName: r.userLastName,
                userMail: r.userMail,
                requestStatus: r.requestStatus,
                tags: r.tags,
                projectId: r.projectId,
                projectLogoUrl: r.projectLogoUrl,
                projectName: r.projectName,
                commentCount: r.commentCount,
                userPhoto: r.userPhoto,
                matchMakingScore: r.matchMakingScore,
                recommended: r.recommended,
                canInviteAdvisor: r.canInviteAdvisor || false,
                requestType: r.requestType,
                invitedAdvisors: [],
                requestResources: r.resources.map((resource: Resource) => ({
                  id: resource.id,
                  name: resource.name,
                  url: resource.resourceUrl,
                })),
                requestIndustryIds: r.industry,
              };
            }, data.list),
          },
          requestFilters: sortCountriesFilter(data.requestFilter),
        });
      })
      .catch(err => reject(err));
  });
}

export function fetchRequestsTags(
  communityId: number,
  bearer: string,
): Promise<{ requestsTags: string[]; communityId: number }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/Tag/GetCommunityRequestTagNameList',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      params: {
        communityId,
      },
    })
      .then(response => {
        const { data } = response;
        const res = {
          requestsTags: data.filter((tag: string, index: number, arr: string[]) => arr.indexOf(tag) === index),
          communityId,
        };
        resolve(res);
      })
      .catch(err => reject(err));
  });
}

export function updateRequestTagsById(
  bearer: string,
  id: number,
  tags: Array<string>,
  communityId: number,
): Promise<{ tags: Array<string>; requestId: number; communityId: number }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'POST',
      url: `/api/Request/${id}/Tag`,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      data: {
        tags,
      },
    })
      .then(response => {
        resolve({
          tags,
          requestId: id,
          communityId,
        });
      })
      .catch(err => reject(err));
  });
}

export function reviewAdvisor(
  bearer: string,
  advisorId: number,
  requestId: number,
  rating: number,
  feedback: string,
  comment: string,
): Promise<void> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'PUT',
      url: `/api/Request/${requestId}/review-advisor`,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      data: {
        advisorId,
        note: feedback,
        rating,
        comment,
      },
    })
      .then(response => resolve())
      .catch(err => {
        reject(err);
      });
  });
}

export function reviewEntrepreneur(
  bearer: string,
  requestId: number,
  rating: number,
  comment: string,
  note: string | null,
): Promise<void> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'PUT',
      url: `/api/Request/${requestId}/review-entrepreneur`,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      data: {
        rating,
        comment,
        note,
      },
    })
      .then(response => {
        if (note) {
          saveRequestComment(bearer, 0, 0, note, 0, requestId, 0).finally(() => resolve());
        } else {
          resolve();
        }
      })
      .catch(err => {
        reject(err);
      });
  });
}

export function fetchCommunityDiscussions(
  communityId: number,
  bearer: string,
  sorting: Sorting,
  filtering: Filtering,
  skip: number,
  take: number,
): Promise<{
  list: CommunityDiscussions;
  skip: number;
  take: number;
  totalDiscussionsCount?: undefined;
  discussionsCount?: number;
}> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'POST',
      url: '/api/CommunityDiscussion/GetDiscussionList',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      data: {
        communityId: communityId,
        take,
        skip,
        ...sorting,
        ...filtering,
      },
    })
      .then((response: AxiosResponse) => {
        const { data } = response;
        resolve({
          skip,
          take,
          discussionsCount: skip === 0 ? data.discussions.count : undefined,
          totalDiscussionsCount: isNoFiltering(filtering) ? data.discussions.count : undefined,
          list: {
            id: communityId,
            communityDiscussions: map(d => {
              return {
                id: d.id,
                name: d.name,
                communityId: communityId,
                projectId: d.projectId,
                commentCount: d.commentCount,
                viewCount: d.viewCount,
                image: d.image,
                content: d.content,
                postDate: d.postDate,
                lastUpdated: d.lastUpdated,
                deadlineDate: d.deadlineDate,
                deadline: d.deadline,
                daysLeft: d.daysLeft,
                paragraphId: d.paragraphId,
                isActive: d.isActive,
                userId: d.userId,
                authorName: d.authorName,
                authorPhoto: d.authorPhoto,
                discussionTags: d.discussionTags,
                discussionSkillCategories: d.discussionSkillCategories,
                isRelatedWithMethodology: d.isRelatedWithMethodology,
                readOnly: d.readOnly,
                isArchived: d.isArchived,
                allowLockComments: d.allowLockComments,
                isLockedCommentDiscussions: d.isLockedCommentDiscussions,
                privacyLevel: d.privacyLevel,
                projectLogoImage: d.projectLogoImage,
                projectName: d.projectName,
                comments: [],
                isFollowing: d.isFollowing,
                communityMembersTags: d.communityMembersTags,
              };
            }, data.discussions.list),
          },
        });
      })
      .catch(err => reject(err));
  });
}

export function acceptProject(communityId: number, bearer: string, projectId: number): Promise<{ projectId: number }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/Community/AcceptProject',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      params: {
        communityId: communityId,
        projectId: projectId,
      },
    })
      .then(() => {
        resolve({ projectId });
      })
      .catch(err => reject(err));
  });
}

export function denyProject(communityId: number, bearer: string, projectId: number): Promise<{ projectId: number }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/Community/DenyProject',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      params: {
        communityId: communityId,
        projectId: projectId,
      },
    })
      .then(() => {
        removeProject(bearer, projectId).then(() => {
          resolve({ projectId });
        });
      })
      .catch(err => reject(err));
  });
}

const cleanFilters = (filters: Partial<CommunityProjectsFilterAnswer>) => {
  const response: Partial<CommunityProjectsFilterAnswer> = {};
  if (filters.countryFilter?.values?.length) {
    response.countryFilter = filters.countryFilter;
  }
  if (filters.tagFilter?.values?.length) {
    response.tagFilter = filters.tagFilter;
  }
  if (filters.developmentStageFilter?.values?.length) {
    response.developmentStageFilter = filters.developmentStageFilter;
  }
  if (filters.industryFilter?.values?.length) {
    response.industryFilter = filters.industryFilter;
  }
  if (filters.sdgFilter?.values?.length) {
    response.sdgFilter = filters.sdgFilter;
  }
  if (filters.programFilter?.values?.length) {
    response.programFilter = filters.programFilter;
  }
  if (filters.concept) {
    response.concept = filters.concept;
  }
  if (filters.filters?.length) {
    response.filters = filters.filters.filter(i => i.answers.length > 0);
  }
  return response;
};

export function fetchCommunityProjects(
  communityId: number,
  bearer: string,
  sorting: Sorting,
  filtering: Partial<CommunityProjectsFilterAnswer>,
  skip: number,
  take: number,
): Promise<{
  list: Project[];
  skip: number;
  take: number;
  projectsCount: number;
  communityId: number;
  totalProjectsCount?: number;
  canCreateProjects: boolean;
  canAccessToOverviewProject: boolean;
  canInviteToProjects: boolean;
  canApproveParagraph: boolean;
  projectFilters: CommunityProjectsFilters;
}> {
  return new Promise((resolve, reject) => {
    const SDGs = getSDGList();
    const filters: Partial<CommunityProjectsFilterAnswer> = cleanFilters(filtering);
    ai({
      method: 'POST',
      url: '/api/Project/GetByCommunity',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      data: {
        communityId: communityId,
        ...sorting,
        ...filters,
        skip,
        take,
      },
    })
      .then((response: AxiosResponse) => {
        const { data } = response;
        const pendingProjects = map(pp => {
          return {
            ...pp,
            isPending: true,
          };
        }, data.pendingProjects);
        const pendingProjectIds = pendingProjects.map((p: Project) => p.id);
        const noDuplicatesProjects = data.projects.list.filter((p: Project) => !contains(p.id, pendingProjectIds));
        const projectsList = map(pl => {
          return {
            ...pl,
            isPending: false,
          };
        }, noDuplicatesProjects);
        const allProjects = concat(pendingProjects, projectsList);
        resolve({
          take,
          skip,
          projectsCount: data.projects.count,
          totalProjectsCount: isNoFiltering(filtering) ? data.projects.count : undefined,
          communityId: communityId,
          canCreateProjects: data.canCreateProjects,
          canAccessToOverviewProject: data.canAccessToOverviewProject,
          canApproveParagraph: data.canApproveParagraph,
          canInviteToProjects: data.canInviteToProjects,
          list: map(project => {
            return {
              id: project.id,
              canEditPrivacy: false,
              communityIds: Array(1).fill(communityId),
              mainCommunityId: project.mainCommunityId,
              name: project.name,
              creationDate: project.creationDate,
              description: project.description,
              logo: sanitizeAssetUrl(project.logoImage),
              cover: sanitizeAssetUrl(project.mainImageOrVideo),
              SDGs: map(s1 => {
                const sdg = filter(s2 => s2.id === s1.sustainableDevelopmentGoalId, SDGs)[0];
                return {
                  id: sdg.id,
                  name: sdg.name,
                  iconName: sdg.iconName,
                };
              }, project.projectSustainableDevelopmentGoals),
              tags: project.projectTags,
              country: project.countryName,
              city: project.city,
              category: project.categoryName,
              stage: project.developmentStage,
              isPending: project.isPending,
              isFollowing: project.isFollowing,
              projectRequestFeedbacks: project.projectRequestFeedbacks,
              businessModel: {
                canEditBusinessModel: false,
                sections: [],
                customPrivacyCircles: [],
              },
            };
          }, allProjects),
          projectFilters: sortCountriesFilter(data.projectFilters),
        });
      })
      .catch(err => reject(err));
  });
}

export function fetchCommunityCircles(
  communityId: number,
  bearer: string,
): Promise<{ communityId: number; circles: Circle[] }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/CommunityCircle/GetByCommunity',
      params: {
        communityId,
      },
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then((response: AxiosResponse) => {
        resolve({
          communityId,
          circles: map(
            c => ({
              id: Number(c.id),
              name: c.name,
              shortName: c.shortName,
              admin: c.isAdminCircle,
              userCount: c.userCount,
              permissionCanInviteToProjects: c.permissionCanInviteToProjects || false,
              permissionCanApproveParagraph: c.permissionCanApproveParagraph || false,
              permissionRequestCanViewAllAndInviteAdvisor: c.permissionRequestCanViewAllAndInviteAdvisor || false,
              permissionProjectCanCreate: c.permissionProjectCanCreate,
              permissionCanAccessToOverviewProject: c.permissionCanAccessToOverviewProject,
              permissionCanBeInvitedAsCoach: c.permissionCanBeInvitedAsCoach,
              permissionCanCoachTeamMembers: c.permissionCanCoachTeamMembers,
            }),
            response.data,
          ),
        });
      })
      .catch(err => reject(err));
  });
}

export function saveCommunityCircles(
  bearer: string,
  communityId: number,
  circles: Circle[],
): Promise<{ communityId: number; circles: Circle[] }> {
  return new Promise((resolve, reject) => {
    (Promise as any)
      .allSettled(circles.map((c: Circle) => saveCommunityCircle(bearer, communityId, c)))
      .then((values: any) => {
        const retVal: { communityId: number; circles: Circle[] } = {
          communityId,
          circles: values
            .filter((promise: any) => promise.status === 'fulfilled')
            .map((promise: any) => promise.value)
            .map((c: { communityId: number; circle: Circle }) => c.circle),
        };
        if (retVal.circles.length === 0) reject(values[0]?.reason);
        else resolve(retVal);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}

export function updateCommunityCircle(
  bearer: string,
  communityId: number,
  circleId: number,
  circleName: string,
  updateFields: object,
): Promise<{ communityId: number; circleId: number; name: string; permission: object }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'POST',
      url: '/api/CommunityCircle/Save',
      data: {
        communityId: communityId,
        id: circleId,
        isEditing: true,
        name: circleName,
        ...updateFields,
      },
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then(() => resolve({ communityId, circleId, name: circleName, permission: updateFields }))
      .catch(err => reject(err));
  });
}

export function saveCommunityCircle(
  bearer: string,
  communityId: number,
  circle: Circle,
): Promise<{ communityId: number; circle: Circle }> {
  return new Promise((resolve, reject) => {
    const newData = {
      communityId,
      editingName: circle.name,
      name: circle.name,
      isEditing: false,
      isSaving: true,
    };
    const updateData = {
      userCount: circle.userCount,
      community: null,
      isAdminCircle: circle.admin,
      id: circle.id,
    };
    let data: any;
    data = {
      ...newData,
    };
    if (circle.id !== 1) {
      data = {
        ...newData,
        ...updateData,
      };
    }
    ai({
      method: 'POST',
      url: '/api/CommunityCircle/Save',
      data,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then((response: AxiosResponse) => {
        const { data } = response;
        resolve({
          communityId,
          circle: {
            id: data.id,
            name: data.name,
            shortName: data.shortName,
            admin: data.isAdminCircle,
            type: {
              name: PrivacyCircleType.community,
              isProject: false,
              isCommunity: true,
            },
            communityId,
            userCount: circle.userCount,
          },
        });
      })
      .catch(err => reject(err));
  });
}

export function deleteCommunityCircle(
  communityId: number,
  bearer: string,
  circleId: number,
): Promise<{ communityId: number; circleId: number }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/CommunityCircle/Remove',
      params: {
        id: circleId,
      },
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then(() => {
        resolve({
          communityId,
          circleId,
        });
      })
      .catch(err => reject(err));
  });
}

export function editCommunityCircle(
  communityId: number,
  bearer: string,
  circleId: string,
  userId: string,
  circleName?: string,
): Promise<{ communityId: number; circleId: number; userId: number; circleName?: string }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/Community/EditCommunityCircle',
      params: {
        communityCircleId: circleId,
        communityId,
        userId,
      },
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then(() => {
        resolve({
          communityId,
          circleId: Number(circleId),
          userId: Number(userId),
          circleName,
        });
      })
      .catch(err => reject(err));
  });
}

export function fetchCommunityMembersCountries(
  bearer: string,
  communityId: number,
): Promise<{ communityId: number; countries: Country[] }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/Country/GetByCommunityUsers',
      params: {
        communityId,
      },
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then((response: AxiosResponse) => {
        resolve({
          communityId,
          countries: map(
            c => ({
              id: Number(c.id),
              name: c.name,
            }),
            response.data.filter((c: any) => c),
          ),
        });
      })
      .catch(err => reject(err));
  });
}

export function fetchCommunityProjectsCountries(
  bearer: string,
  communityId: number,
): Promise<{ communityId: number; countries: Country[] }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/Country/GetByCommunityProjects',
      params: {
        communityId,
      },
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then((response: AxiosResponse) => {
        resolve({
          communityId,
          countries: map(
            c => ({
              id: Number(c.id),
              name: c.name,
            }),
            response.data.filter((c: any) => c),
          ),
        });
      })
      .catch(err => reject(err));
  });
}

export function fetchCommunityProjectsCategories(
  bearer: string,
  communityId: number,
): Promise<{ communityId: number; categories: ProjectCategory[] }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/Category/GetByCommunityProjects',
      params: {
        communityId,
      },
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then((response: AxiosResponse) => {
        resolve({
          communityId,
          categories: response.data as ProjectCategory[],
        });
      })
      .catch(err => reject(err));
  });
}

export function fetchCommunityProjectsStages(
  bearer: string,
  communityId: number,
): Promise<{ communityId: number; stages: ProjectStage[] }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/Community/GetProjectDevelopmentStages',
      params: {
        communityId,
      },
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then((response: AxiosResponse) => {
        const allStages = getDevelopmentStateList().reduce((acc, val) => [...acc, ...val], []);
        resolve({
          communityId,
          stages: response.data
            .map((stage: string) => allStages.find((s: ProjectStage) => stage === s.key))
            .filter((stage: any) => stage !== undefined),
        });
      })
      .catch(err => reject(err));
  });
}

export function fetchCommunityProjectsSDGs(
  bearer: string,
  communityId: number,
): Promise<{ communityId: number; sdgs: SDG[] }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/SustainableDevelopmentGoal/GetByCommunityProjects',
      params: {
        communityId,
      },
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then((response: AxiosResponse) => {
        resolve({
          communityId,
          sdgs: response.data,
        });
      })
      .catch(err => reject(err));
  });
}

export function fetchCommunityProjectsTags(
  bearer: string,
  communityId: number,
): Promise<{ communityId: number; tags: string[] }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/Tag/GetProjectTagNameListByCommunity',
      params: {
        communityId,
      },
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then((response: AxiosResponse) => {
        resolve({
          communityId,
          tags: response.data,
        });
      })
      .catch(err => reject(err));
  });
}

export function saveCommunityProjectsTags(
  bearer: string,
  projectId: number,
  communityId: number,
  tags: string[],
): Promise<{ projectId: number; communityId: number; tags: string[] }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'POST',
      url: '/api/Project/SaveTags',
      data: {
        projectId,
        projectTags: tags,
      },
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then(() => {
        resolve({
          projectId,
          communityId,
          tags,
        });
      })
      .catch(err => reject(err));
  });
}

export function saveCommunityProjectsPrivacy(
  bearer: string,
  projectId: number,
  communityId: number,
  communityCircleIdList: number[],
  projectCommunityUserCircleTagList: string[],
): Promise<void> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'POST',
      url: '/api/Project/UpdateProjectCommunityCircle',
      data: {
        communityId,
        projectId,
        communityCircleIdList,
        projectCommunityUserCircleTagList,
      },
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then(() => {
        resolve();
      })
      .catch(err => reject(err));
  });
}

export function fetchProjectTags(bearer: string): Promise<{ tags: string[] }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/Tag/GetProjectTagNameList',
      params: {
        skip: 0,
        take: 0,
      },
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then((response: AxiosResponse) => {
        resolve({
          tags: response.data,
        });
      })
      .catch(err => reject(err));
  });
}

export function fetchCommunityMembersSkills(
  bearer: string,
  communityId: number,
): Promise<{ communityId: number; skills: Skill[] }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/SkillCategory/GetByCommunityUsers',
      params: {
        communityId,
      },
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then((response: AxiosResponse) => {
        resolve({
          communityId,
          skills: map(
            c => ({
              id: Number(c.id),
              name: c.name,
            }),
            response.data,
          ),
        });
      })
      .catch(err => reject(err));
  });
}

export function fetchCommunityMembersInterests(
  bearer: string,
  communityId: number,
): Promise<{ communityId: number; interests: Interest[] }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/Interest/GetByCommunityUsers',
      params: {
        communityId,
      },
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then((response: AxiosResponse) => {
        resolve({
          communityId,
          interests: map(
            c => ({
              id: Number(c.id),
              name: c.name,
            }),
            response.data,
          ),
        });
      })
      .catch(err => reject(err));
  });
}

export function fetchMentionUsers(
  bearer: string,
  communityId: number,
  query: string,
): Promise<{ communityId: number; mentionUsers: MentionUser[] }> {
  return new Promise((resolve, reject) => {
    fetchCommunityMembers(bearer, communityId, {}, { name: query }, 0, 5, false).then(
      (response: { list: CommunityMembers }) => {
        resolve({
          communityId,
          mentionUsers: (response.list.members || []).map((m: Member) => ({
            id: `@${m.name}`,
            userId: m.id,
            name: m.name,
          })),
        });
      },
    );
  });
}

export function fetchCommunityMembers(
  bearer: string,
  communityId: number,
  sorting: Sorting,
  filtering: Filtering,
  skip: number,
  take: number,
  fetchAll?: boolean,
): Promise<{
  list: CommunityMembers;
  skip: number;
  take: number;
  isCommunityAdmin: boolean;
  membersCount?: number;
  totalMembersCount?: number;
  memberFilters?: CommunityMembersFilters;
}> {
  const filters: any = { ...filtering };
  for (const filter in filters) {
    if (filter === 'marketExpertiseFilter') {
      filters.userMarketsExpertisesFilter = filters[filter];
      delete filters[filter];
    }
    if (filters[filter] === '0' || filters[filter] === '') {
      delete filters[filter];
    } else {
      if (!isNaN(Number(filters[filter]))) filters[filter] = Number(filters[filter]);
    }
  }
  const data = {
    communityId: communityId.toString(),
    ...sorting,
    ...filters,
  };
  if (!fetchAll) {
    data.skip = skip;
    data.take = take;
  }
  return new Promise((resolve, reject) => {
    ai({
      method: 'POST',
      url: '/api/Community/GetUserList',
      data,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then((response: AxiosResponse) => {
        const { data } = response;
        const userMap = (user: any): Member => {
          return {
            id: Number(user.id),
            name: `${user.firstName} ${user.lastName}`,
            photo: sanitizeAssetUrl(user.photoUrl),
            occupation: user.occupation,
            email: user.mail,
            tags: [],
            userTags: user.userTags,
            country: {
              id: user.country ? user.country.id : 0,
              name: user.country ? user.country.name : '-',
            },
            skills: map((s: any) => {
              return { name: s.skill.name, id: s.skill.id, popUpText: s.skill.name };
            }, user.userSkills || []),
            interests: map(i => {
              return { name: i.interest.name, id: i.interest.id };
            }, user.userInterests || []),
            circle: { id: user.communityCircleId, name: '', shortName: '', admin: false },
            isFollowed: user.isFollowedByMe,
          };
        };
        // TODO: refactor: add name and isAdmin in circle
        resolve({
          skip,
          take,
          isCommunityAdmin: data.isAdmin,
          membersCount: skip === 0 ? data.users.count : undefined,
          totalMembersCount: isNoFiltering(filtering) ? data.users.count : undefined,
          list: {
            id: communityId,
            members: map(userMap, data.users.list),
            pendingUsers: data.pendingUsers ? map(userMap, data.pendingUsers) : [],
            pendingByUsers: map(userMap, data.pendingByUsers),
          },
          memberFilters: sortCountriesFilter(data.communityFilters),
        });
      })
      .catch(err => reject(err));
  });
}

export function fetchCommunityMembersPendingByUser(
  bearer: string,
  communityId: number,
): Promise<{
  id: number;
  pendingByUsers: Member[];
}> {
  return new Promise((resolve, reject) => {
    fetchCommunityMembers(bearer, communityId, {}, {}, 0, 1, false)
      .then((response: { list: CommunityMembers }) => {
        resolve({
          id: response.list.id,
          pendingByUsers: response.list.pendingByUsers || [],
        });
      })
      .catch(err => reject(err));
  });
}

export function respondCommunityJoinRequest(
  bearer: string,
  communityId: number,
  userId: number,
  type: Response,
): Promise<{
  communityId: number;
  userId: number;
  type: Response;
}> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: `/api/Community/${type === Response.Accept ? 'Approve' : 'Deny'}JoinRequest`,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      params: {
        communityId,
        userId,
      },
    })
      .then(() =>
        resolve({
          communityId,
          userId,
          type,
        }),
      )
      .catch(err => reject(err));
  });
}

export function removeCommunityInvitation(
  bearer: string,
  communityId: number,
  userEmail: string,
  userId: number,
): Promise<{
  communityId: number;
  userEmail: string;
  userId: number;
}> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/Community/RemoveInvitation',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      params: {
        communityId,
        email: userEmail,
        userId,
      },
    })
      .then(() =>
        resolve({
          communityId,
          userEmail,
          userId,
        }),
      )
      .catch(err => reject(err));
  });
}

export function inviteCommunityMember(bearer: string, user: EmailInvitationDto): Promise<EmailInvitationDto> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'POST',
      url: '/api/User/InviteToCommunityViaEmail',
      data: user,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then(() => {
        resolve(user);
      })
      .catch(err => reject(err));
  });
}

export function inviteCommunityMembers(
  bearer: string,
  users: EmailInvitationDto[],
): Promise<(EmailInvitationDto | AxiosError)[]> {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const answers: (EmailInvitationDto | AxiosError)[] = [];
    for (let userIndex = 0; userIndex < users.length; userIndex++) {
      const user = users[userIndex];
      try {
        const invitedUser = await inviteCommunityMember(bearer, user);
        answers.push(invitedUser);
      } catch (err: any) {
        answers.push(err);
      }
    }
    resolve(answers);
  });
}

export function createSingleBulkUser(bearer: string, user: BulkInvitee): Promise<BulkInvitee> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'POST',
      url: '/api/User/SignUpImport',
      data: [user],
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then(() => {
        resolve(user);
      })
      .catch(err => reject(err));
  });
}

export function createBulkUsers(bearer: string, users: BulkInvitee[]): Promise<(BulkInvitee | AxiosError)[]> {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const answers: (BulkInvitee | AxiosError)[] = [];
    for (let userIndex = 0; userIndex < users.length; userIndex++) {
      const user = users[userIndex];
      try {
        const invitedUser = await createSingleBulkUser(bearer, user);
        answers.push(invitedUser);
      } catch (err: any) {
        answers.push(err);
      }
    }
    resolve(answers);
  });
}

function updateCommunityDescription(
  description: string | undefined,
  bearer: string,
  communityId: number,
): Promise<boolean> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'POST',
      url: '/api/Community/SaveAbout',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      data: {
        id: communityId,
        description: description || '',
      },
    })
      .then(() => {
        resolve(true);
      })
      .catch(err => reject(err));
  });
}

export function updateCommunityInfo(
  communityInfo: Community,
  bearer: string,
  logoFile: File | string,
  coverFile: File | string,
): Promise<Community> {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let newLogo = sanitizeAssetUrl(communityInfo.logo);
    let newCover = sanitizeAssetUrl(communityInfo.coverImage);
    let logoFilePromise;
    let coverFilePromise;
    if (typeof logoFile !== 'string')
      logoFilePromise = uploadFile(
        bearer,
        { file: logoFile, folder: 'community_image' },
        ImageType.CommunityLogoImage,
      ).then(logoResource => {
        if (logoResource.link !== '/') newLogo = sanitizeAssetUrl(logoResource.link);
      });
    if (typeof coverFile !== 'string')
      coverFilePromise = uploadFile(bearer, { file: coverFile, folder: 'community_image' }).then(coverResource => {
        if (coverResource.link !== '/') newCover = sanitizeAssetUrl(coverResource.link);
      });

    if (logoFilePromise) await logoFilePromise;
    if (coverFilePromise) await coverFilePromise;
    ai({
      method: 'POST',
      url: '/api/Community/SaveInfo',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      data: {
        id: communityInfo.id,
        name: communityInfo.name,
        logoImage: newLogo,
        mainImage: newCover,
        shortDescription: communityInfo.shortDescription,
        countryId: communityInfo.countryId,
        city: communityInfo.city,
        menuLogoImage: sanitizeAssetUrl(communityInfo.menuLogoImage),
        communityTags: communityInfo.communityTags,
        hideParagraphsUntilDate: communityInfo.hideParagraphsUntilDate,
        generalSettingsHasLockTags: communityInfo.generalSettingsHasLockTags,
        defaultProjectFilterTag: communityInfo.defaultProjectFilterTag,
        description: communityInfo.description,
        allowTasks: communityInfo.allowTasks,
      },
    })
      .then((response: AxiosResponse) => {
        const { data } = response;
        updateCommunityDescription(communityInfo.description, bearer, communityInfo.id).then(() => {
          resolve({
            ...data,
            logo: data.logoImage,
            coverImage: data.mainImage,
            description: communityInfo.description,
          });
        });
      })
      .catch(err => reject(err));
  });
}

export function downloadStatistics(communityId: number, bearer: string, statistic: StatsType): Promise<string> {
  const dateFrom = moment().subtract(1825, 'days').format('YYYY-MM-DD');
  const dateTo = moment().format('YYYY-MM-DD');

  return new Promise((resolve, reject) => {
    let url = '';
    switch (statistic) {
      case StatsType.users:
        url = '/api/Stat/CommunityUsersStats';
        break;

      case StatsType.projects:
        url = '/api/Stat/CommunityProjectsStats';
        break;
      case StatsType.events:
        url = '/api/Calendar/ExportEventsToCsv';
        break;
      default:
        break;
    }
    if (url) {
      ai({
        method: 'GET',
        url: url,
        headers: {
          Authorization: `Bearer ${bearer}`,
        },
        params: {
          communityId,
          dateFrom,
          dateTo,
        },
      })
        .then((response: AxiosResponse) => {
          resolve(response.data);
        })
        .catch(err => reject(err));
    } else {
      reject(new Error('Incorrect statistics: ' + statistic));
    }
  });
}

export function fetchInstantStatistics(communityId: number, bearer: string, statistic: StatsType): Promise<CSVTable> {
  const decodeCsv = (text: string) => {
    const txt = document.createElement('textarea');
    txt.innerHTML = text;
    return txt.value;
  };
  function parseCSV(csvString: string) {
    const lines = [];
    let currentLine = '';
    let withinQuotes = false;

    for (let i = 0; i < csvString.length; i++) {
      const char = csvString[i];

      if (char === '"') {
        withinQuotes = !withinQuotes;
      }

      if (char === '\n' && !withinQuotes) {
        lines.push(currentLine.trim());
        currentLine = '';
      } else {
        currentLine += char;
      }
    }

    if (currentLine.length > 0) {
      lines.push(currentLine.trim());
    }

    return lines;
  }
  function splitCSVLine(line: string) {
    const columns = [];
    let currentColumn = '';
    let withinQuotes = false;

    for (let i = 0; i < line.length; i++) {
      const char = line[i];

      if (char === '"') {
        withinQuotes = !withinQuotes;
      }

      if (char === ',' && !withinQuotes) {
        columns.push(currentColumn);
        currentColumn = '';
      } else {
        currentColumn += char;
      }
    }

    columns.push(currentColumn); // Push the last column

    return columns.map(column => column.trim());
  }
  return new Promise((resolve, reject) => {
    downloadStatistics(communityId, bearer, statistic)
      .then((statistics: string) => {
        const rows = parseCSV(decodeCsv(statistics));
        const columnNames = rows[0].split(',');
        const data: CSVTable = [];
        for (let rowIndex = 1; rowIndex < rows.length; rowIndex++) {
          const values = splitCSVLine(rows[rowIndex]);
          if (rowIndex === rows.length - 1 && rows[rowIndex].trim() === '') continue;
          if (columnNames.length !== values.length) {
            console.error('Something is wrong with the statistics data', columnNames, values);
            continue;
          }
          data.push({});
          data[data.length - 1].All = statistic;
          for (let columnIndex = 0; columnIndex < columnNames.length; columnIndex++)
            data[data.length - 1][columnNames[columnIndex]] = values[columnIndex];
        }
        resolve(data);
      })
      .catch(err => {
        reject(err);
      });
  });
}

export function fetchHistoricalStatistics(
  communityId: number,
  bearer: string,
  statistic: StatsType,
): Promise<{ name: string; data: CSVTable }[]> {
  return new Promise((resolve, reject) => {
    let url = '';
    switch (statistic) {
      case StatsType.users:
        url = '/api/CommunityDashboard/UserValues';
        break;
      case StatsType.projects:
        url = '/api/CommunityDashboard/ProjectValues';
        break;
    }
    const currentDate = new Date();
    if (url) {
      ai({
        method: 'GET',
        url,
        headers: {
          Authorization: `Bearer ${bearer}`,
        },
        params: {
          communityId,
          startMonth: 1,
          startYear: 2021,
          endMonth: currentDate.getMonth() === 0 ? 11 : currentDate.getMonth(),
          endYear: currentDate.getMonth() === 0 ? currentDate.getFullYear() - 1 : currentDate.getFullYear(),
        },
      })
        .then((response: AxiosResponse) => {
          const { data } = response;
          const tempObj = data
            .map((row: any) => {
              if (statistic === StatsType.users) {
                return {
                  ...row,
                  userIsActive:
                    (new Date().getTime() - new Date(row.userLastLoginDate).getTime()) / 1000 / 60 / 60 / 24 <= 7,
                };
              }
              return row;
            })
            .sort((a: any, b: any) => (a.year === b.year ? a.month - b.month : a.year - b.year))
            .reduce((acc: { [name: string]: CSVTable }, row: any) => {
              const { year, month, ...restRow } = row;

              const rowName = `${year} ${month}`;
              return {
                ...acc,
                [rowName]: [...(acc[rowName] || []), { ...restRow, All: statistic }],
              };
            }, {});
          const months = getMonths();
          resolve(
            Object.keys(tempObj).map((key: string) => ({
              name:
                months[Number(key.split(' ')[1] || 1) - 1].text +
                (Number(key.split(' ')[1]) === 1 ? "'" + key.split(' ')[0].substr(2) : ''),
              data: tempObj[key],
            })),
          );
        })
        .catch(err => reject(err));
    } else {
      reject(new Error('Incorrect statistics: ' + statistic));
    }
  });
}

export function deleteStatisticsReport(
  bearer: string,
  communityId: number,
  id: number,
): Promise<{ communityId: number; id: number }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'DELETE',
      url: `/api/CommunityDashboard/${id}`,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then(() => {
        resolve({ communityId: communityId, id: id });
      })
      .catch(err => reject(err));
  });
}

export function fetchResourceCircles(
  bearer: string,
  entityId: number,
  isCommunityResource: boolean,
  resourceId?: number,
): Promise<{ entityId: number; circles: Circle[]; isCommunityResource: boolean; resourceId?: number }> {
  return new Promise((resolve, reject) => {
    let url: string;
    const params: any = {
      resourceId: resourceId || 0,
    };
    if (isCommunityResource) {
      url = '/api/Privacy/GetCommunityResourcePrivacyCustomCircles';
      params.communityId = entityId;
    } else {
      url = '/api/Privacy/GetProjectResourcePrivacyCustomCircles';
      params.projectId = entityId;
    }
    ai({
      method: 'GET',
      url,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      params,
    })
      .then((response: AxiosResponse) => {
        const { data } = response;
        const auxCircles: any[] = [];
        data.forEach((c1: any) => {
          c1.privacyCircles.forEach((c2: any) => {
            auxCircles.push({ ...c2, typeName: c1.name, isProject: c1.isProject, isCommunity: c1.isCommunity });
          });
        });
        resolve({
          isCommunityResource,
          entityId,
          resourceId: resourceId,
          circles: map(c => {
            return {
              id: c.id,
              name: c.longName,
              shortName: c.name,
              admin: c.name === 'Admins',
              isActive: c.isActive,
              userCount: c.userCount,
              projectId: c.projectId,
              communityId: c.communityId,
              type: {
                name: c.typeName,
                isProject: c.isProject,
                isCommunity: c.isCommunity,
              },
            };
          }, auxCircles),
        });
      })
      .catch(err => reject(err));
  });
}

export function setAllowTasks(
  bearer: string,
  communityId: number,
  allowTasks: boolean,
): Promise<{ id: number; allowTasks: boolean }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'POST',
      url: `/api/Community/UpdateAllowTasks/${communityId}`,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      data: {
        allowTasks,
      },
    })
      .then(() => resolve({ id: communityId, allowTasks }))
      .catch(err => reject(err));
  });
}

export function fetchProjectsAwaitingFeedback(
  bearer: string,
  communityId: number,
): Promise<{ communityId: number; projects: ProjectAwaitingFeedback[] }> {
  return new Promise(resolve => {
    ai({
      method: 'GET',
      url: '/api/Community/AwaitingFeedbackProjects',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      params: {
        communityId,
      },
    }).then((response: AxiosResponse) => {
      const { data } = response;
      resolve({
        communityId,
        projects: map(p => {
          return {
            ...p,
            logo: p.logoImage,
          };
        }, data),
      });
    });
  });
}

function _fetchMultipleProjectsNextParagraphs(
  bearer: string,
  communityId: number,
): Promise<{ communityId: number; projects: ProjectNextParagraphs[] }> {
  return new Promise(resolve => {
    ai({
      method: 'GET',
      url: '/api/Community/ProjectNextParagraphs',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      params: {
        communityId,
      },
    }).then((response: AxiosResponse) => {
      const { data } = response;
      if (!data)
        resolve({
          communityId,
          projects: [],
        });
      resolve({
        communityId,
        projects: map((p: any) => {
          return {
            id: p.projectId,
            name: p.projectName,
            logo: p.projectLogoImage,
            sections: p.businessModelSections
              ?.sort((a: any, b: any) => a.order - b.order)
              .map((s: any) => {
                return {
                  id: s.id,
                  name: s.name,
                  logo: s.logo,
                  assignments: s.paragraphs
                    .sort((a: any, b: any) => a.order - b.order)
                    .map((a: any) => {
                      return {
                        id: a.id,
                        name: a.name,
                        content: a.content,
                      };
                    }),
                };
              }),
            nextParagraph: {
              id: p.nextParagraph.id,
              name: p.nextParagraph.name,
              description: p.nextParagraph.wikiReferences,
              taskDateStart: p.taskDateStart,
            },
          };
        }, data),
      });
    });
  });
}

function _fetchSingleProjectNextParagraphs(
  bearer: string,
  communityId: number,
  projectId: number,
): Promise<{ communityId: number; projects: ProjectNextParagraphs[] }> {
  return new Promise(resolve => {
    ai({
      method: 'GET',
      url: '/api/BusinessModelContent/ProjectNextParagraphs',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      params: {
        id: projectId,
      },
    }).then((response: AxiosResponse) => {
      const { data } = response;
      if (!data)
        resolve({
          communityId,
          projects: [],
        });
      resolve({
        communityId,
        projects: [
          {
            id: data.projectId,
            name: data.projectName,
            logo: data.projectLogoImage,
            sections: data.businessModelSections
              ?.sort((a: any, b: any) => a.order - b.order)
              .map((s: any) => {
                return {
                  id: s.id,
                  name: s.name,
                  logo: s.logo,
                  assignments: s.paragraphs
                    .sort((a: any, b: any) => a.order - b.order)
                    .map((a: any) => {
                      return {
                        id: a.id,
                        name: a.name,
                        content: a.content,
                      };
                    }),
                };
              }),
            nextParagraph: {
              id: data.nextParagraph?.id,
              name: data.nextParagraph?.name,
              description: mentionParse(data.nextParagraph?.wikiReferences),
              taskDateStart: data.taskDateStart,
            },
          },
        ],
      });
    });
  });
}

export function fetchProjectsNextParagraphs(
  bearer: string,
  communityId: number,
  projectId?: number,
): Promise<{ communityId: number; projects: ProjectNextParagraphs[] }> {
  if (projectId) {
    return _fetchSingleProjectNextParagraphs(bearer, communityId, projectId);
  } else {
    return _fetchMultipleProjectsNextParagraphs(bearer, communityId);
  }
}

export function removeUserFromCommunity(
  bearer: string,
  communityId: number,
  userId: number,
): Promise<{ communityId: number; userId: number }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/Community/AbandonCommunity',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      params: {
        communityId: communityId,
        userId: userId,
      },
    })
      .then(() => {
        resolve({
          communityId: communityId,
          userId: userId,
        });
      })
      .catch(err => reject(err));
  });
}

export function fetchCommunityProgressTracking(
  bearer: string,
  communityId: number,
): Promise<{ progressOverview: CommunityProgressOverview; communityId: number }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/ProjectTracking/Overview',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      params: {
        communityId: communityId,
      },
    })
      .then((response: AxiosResponse) => {
        resolve({
          communityId,
          progressOverview: response.data,
        });
      })
      .catch(err => reject(err));
  });
}

export function acceptCommunityInvitation(
  bearer: string,
  communityId: number,
  userId: number,
): Promise<{ communityId: number }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/Community/AcceptInvitation',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      params: {
        communityId,
        userId,
      },
    })
      .then(() => {
        resolve({
          communityId,
        });
      })
      .catch(err => reject(err));
  });
}

export function declineCommunityInvitation(
  bearer: string,
  communityId: number,
  userId: number,
): Promise<{ communityId: number }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/Community/DeclineInvitation',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      params: {
        communityId,
        userId,
      },
    })
      .then(() => {
        resolve({
          communityId,
        });
      })
      .catch(err => reject(err));
  });
}

export function fetchCommunityRequestEvents(
  bearer: string,
  communityId: number,
  dateFrom: string,
  dateTo: string,
): Promise<{ communityId: number; eventList: CalendarEvent[] }> {
  return new Promise((resolve, reject) => {
    // TODO: temporary fix for request events
    resolve({
      communityId,
      eventList: [],
    });
    /*
    ai({
      method: 'GET',
      url: `/api/Request/community/${communityId}/event-list`,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      params: {
        dateFrom,
        dateTo,
        communityId,
      },
    })
      .then((response: AxiosResponse) => {
        const { data } = response;
        resolve({
          communityId,
          eventList: data.map(
            (e: any): CalendarEvent => {
              const conferenceLink =
                (e.conferenceData &&
                  e.conferenceData.entryPoints.find((entryPoint: any) => entryPoint.entryPointType === 'video')?.uri) ||
                '';
              return {
                attendeesCount: e.attendeesCount,
                id: e.eventId,
                summary: e.summary,
                description: e.description,
                startDate: moment(e.start).format('YYYY-MM-DD'),
                startTime: moment(e.start).format('HH:mm'),
                endDate: moment(e.end).format('YYYY-MM-DD'),
                endTime: moment(e.end).format('HH:mm'),
                location: e.location || conferenceLink,
                isUserInAttendeeList: e.isUserInAttendeeList,
                userCanEdit: e.userCanEdit,
              };
            },
          ),
        });
      })
      .catch(err => reject(err));
      */
  });
}

export function fetchCommunityOnlyCalendarEvents(
  bearer: string,
  communityId: number,
  dateFrom: string,
  dateTo: string,
): Promise<{ communityId: number; eventList: CalendarEvent[] }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/Calendar/GetEventList',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      params: {
        dateFrom,
        dateTo,
        communityId,
      },
    })
      .then((response: AxiosResponse) => {
        const { data } = response;
        resolve({
          communityId,
          eventList: data.map(
            (e: any): CalendarEvent => {
              const conferenceLink =
                (e.conferenceData &&
                  e.conferenceData.entryPoints.find((entryPoint: any) => entryPoint.entryPointType === 'video')?.uri) ||
                '';
              return {
                attendeesCount: e.attendeesCount,
                id: e.eventId,
                summary: e.summary,
                description: e.description,
                startDate: moment(e.start).format('YYYY-MM-DD'),
                startTime: moment(e.start).format('HH:mm'),
                endDate: moment(e.end).format('YYYY-MM-DD'),
                endTime: moment(e.end).format('HH:mm'),
                location: e.location || conferenceLink,
                isUserInAttendeeList: e.isUserInAttendeeList,
                userCanEdit: e.userCanEdit,
              };
            },
          ),
        });
      })
      .catch(err => reject(err));
  });
}

export function fetchCommunityCalendarEvents(
  bearer: string,
  communityId: number,
  dateFrom: string,
  dateTo: string,
): Promise<{ communityId: number; eventList: CalendarEvent[] }> {
  return new Promise((resolve, reject) => {
    Promise.allSettled([
      fetchCommunityOnlyCalendarEvents(bearer, communityId, dateFrom, dateTo),
      fetchCommunityRequestEvents(bearer, communityId, dateFrom, dateTo),
    ])
      .then(responses => {
        const unsortedEvents = [
          ...((responses[0].status === 'fulfilled' && responses[0].value && responses[0].value.eventList) || []),
          ...((responses[1].status === 'fulfilled' && responses[1].value && responses[1].value.eventList) || []),
        ];
        const sortedEvents = unsortedEvents.sort(
          (a: CalendarEvent, b: CalendarEvent) =>
            moment(`${a.startDate}T${a.startTime}`).unix() - moment(`${b.startDate}T${b.startTime}`).unix(),
        );
        resolve({
          communityId,
          eventList: sortedEvents,
        });
      })
      .catch(err => {
        reject(err);
      });
  });
}

export function fetchCommunityCalendarEventDetails(
  bearer: string,
  communityId: number,
  eventId: string,
): Promise<{ communityId: number; event: CalendarEvent }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/Calendar/GetEventDetails',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      params: {
        communityId,
        eventId,
      },
    })
      .then((response: AxiosResponse) => {
        const { data } = response;
        const conferenceLink =
          (data.conferenceData &&
            data.conferenceData.entryPoints.find((entryPoint: any) => entryPoint.entryPointType === 'video')?.uri) ||
          '';
        resolve({
          communityId,
          event: {
            attendeesCount: data.attendeeUsers.length,
            attendeeUsers: data.attendeeUsers
              .map((user: any) => {
                return {
                  id: user.id,
                  name: `${user.firstName} ${user.lastName}`,
                  comparator: `${user.firstName} ${user.lastName}`.toUpperCase(),
                  email: user.mail,
                  photoUrl: user.photoUrl,
                  responseStatus: user.responseStatus,
                };
              })
              .sort((a: any, b: any) => {
                if (a.comparator > b.comparator) return 1;
                if (a.comparator === b.comparator) return 0;
                return -1;
              }),
            id: data.eventId,
            summary: data.summary,
            description: data.description,
            isUserInAttendeeList: data.isUserInAttendeeList,
            userCanEdit: data.userCanEdit,
            location: data.location || conferenceLink,
            startDate: moment(data.start).format('YYYY-MM-DD'),
            startTime: moment(data.start).format('HH:mm'),
            endDate: moment(data.end).format('YYYY-MM-DD'),
            endTime: moment(data.end).format('HH:mm'),
          },
        });
      })
      .catch(err => reject(err));
  });
}

export function upsertCommunityCalendarEvent(
  bearer: string,
  communityId: number,
  eventToSave: CalendarEvent,
): Promise<{ communityId: number; event: CalendarEvent }> {
  // TODO: backend we need to send time in unix timestamp
  return new Promise((resolve, reject) => {
    ai({
      method: 'POST',
      url: '/api/Calendar/SaveEvent',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      data: {
        communityId,
        attendeeUserIds: eventToSave.attendeeUsers ? eventToSave.attendeeUsers.map((a: EventAttendee) => a.id) : [],
        eventId: eventToSave.id === '' ? '' : eventToSave.id,
        summary: eventToSave.summary,
        description: eventToSave.description,
        location: eventToSave.location,
        createConference: eventToSave.createConference,
        start: moment(`${eventToSave.startDate}T${eventToSave.startTime}`, 'YYYY-MM-DDTHH:mm:ss').toISOString(),
        end: moment(`${eventToSave.endDate}T${eventToSave.endTime}`, 'YYYY-MM-DDTHH:mm:ss').toISOString(),
        meetingOriginType: eventToSave.meetingOriginType,
      },
    })
      .then((response: AxiosResponse) => {
        const { data } = response;
        const conferenceLink =
          (data.conferenceData &&
            data.conferenceData.entryPoints.find((entryPoint: any) => entryPoint.entryPointType === 'video')?.uri) ||
          '';
        resolve({
          communityId,
          event: {
            ...eventToSave,
            location: eventToSave.location || conferenceLink,
            id: data.eventId,
            isUserInAttendeeList: data.isUserInAttendeeList,
            userCanEdit: data.userCanEdit,
          },
        });
      })
      .catch(err => reject(err));
  });
}

export function deleteCommunityCalendarEvent(
  bearer: string,
  communityId: number,
  eventId: string,
): Promise<{ communityId: number; eventId: string }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/Calendar/RemoveEvent',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      params: {
        communityId,
        eventId,
      },
    })
      .then(() => {
        resolve({
          communityId,
          eventId,
        });
      })
      .catch(err => reject(err));
  });
}

export function searchCommunityProgressTracking(
  bearer: string,
  communityId: number,
  concept?: string,
  categoryId?: number,
  countryId?: number,
  sustainableDevelopmentGoalsIds?: number,
  tags?: string[],
): Promise<{
  data: CommunityProgressOverview;
  communityId: number;
}> {
  const params: any = {
    communityId,
  };
  if (concept) params.concept = concept;
  if (categoryId) params.categoryId = categoryId;
  if (countryId) params.countryId = countryId;
  if (sustainableDevelopmentGoalsIds) params.sustainableDevelopmentGoalsIds = sustainableDevelopmentGoalsIds;
  // TODO: refactor this one liner
  const tagsQuery: string =
    tags && tags.length
      ? `?${tags
          .map((tag: string, i: number): string => `${i !== 0 ? '&' : ''}tags=${encodeURIComponent(tag)}`)
          .join('')}`
      : '';
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: `/api/ProjectTracking/Search${tagsQuery}`,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      params,
    })
      .then((response: AxiosResponse) => {
        resolve({
          data: response.data,
          communityId,
        });
      })
      .catch(err => reject(err));
  });
}

export function fetchKPI(
  bearer: string,
  methodologyId: number,
  communityId: number,
): Promise<{ communityId: number; methodologyId: number; kpis: KpiDefinition[] }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/KpiDefinition/GetByMethodology',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      params: {
        methodologyId: methodologyId,
      },
    })
      .then((response: AxiosResponse) => {
        const { data } = response;
        resolve({
          communityId,
          methodologyId,
          kpis: data,
        });
      })
      .catch(err => reject(err));
  });
}
export function fetchCommunityNameKPIJsonStats(
  bearer: string,
  communityId: number,
  kpiName: string,
): Promise<KPIList[]> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/KPIReport/GetByCommunityAndKPINameJson',
      params: {
        communityId,
        kpiName,
      },
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then((response: AxiosResponse) => {
        const kpis = response.data.filter((kpi: any) => {
          const startDate = new Date(kpi.kpiDefinitionStartYear, kpi.kpiDefinitionStartMonth - 1, 1);
          const currentDate = new Date();
          const startYearMonth = startDate.getFullYear() * 100 + startDate.getMonth();
          const currentYearMonth = currentDate.getFullYear() * 100 + currentDate.getMonth();
          return startYearMonth <= currentYearMonth;
        });
        resolve(kpis);
      })
      .catch(err => reject(err));
  });
}
export function fetchMultipleMethodologyKPIs(
  bearer: string,
  methodologyIds: number[],
): Promise<{ methodologyId: number; kpiId: number; kpiName: string }[]> {
  return new Promise((resolve, reject) => {
    Promise.all(methodologyIds.map(methodologyId => fetchKPI(bearer, methodologyId, 0)))
      .then((responses: { kpis: KpiDefinition[] }[]) => {
        const res: { methodologyId: number; kpiId: number; kpiName: string }[] = responses.reduce(
          (acc: { methodologyId: number; kpiId: number; kpiName: string }[], val: { kpis: KpiDefinition[] }) => [
            ...acc,
            ...(val.kpis.map((kpi: KpiDefinition) => ({
              methodologyId: kpi.methodologyId,
              kpiId: kpi.id,
              kpiName: kpi.name,
            })) as { methodologyId: number; kpiId: number; kpiName: string }[]),
          ],
          [] as { methodologyId: number; kpiId: number; kpiName: string }[],
        );
        resolve(res);
      })
      .catch(err => reject(err));
  });
}

export function deleteKPI(
  bearer: string,
  communityId: number,
  kpi: KpiDefinition,
): Promise<{ communityId: number; kpi: KpiDefinition }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/KpiDefinition/Remove',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      params: {
        id: kpi.id,
      },
    })
      .then(() => {
        resolve({ communityId, kpi });
      })
      .catch(err => reject(err));
  });
}

export function upsertKPI(
  bearer: string,
  communityId: number,
  kpi: KpiDefinition,
): Promise<{ communityId: number; kpi: KpiDefinition }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'POST',
      url: '/api/KpiDefinition/Save',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      data: {
        ...kpi,
      },
    })
      .then((response: AxiosResponse) => {
        const { data } = response;
        resolve({ communityId, kpi: { ...kpi, id: data } });
      })
      .catch(err => reject(err));
  });
}

export function downloadKPIExcel(bearer: string, methodologyId: number): Promise<string> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/KpiReport/GetByMethodologyProjectsPerLine',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      params: {
        methodologyId,
      },
    })
      .then((response: AxiosResponse) => {
        const { data } = response;
        resolve(data);
      })
      .catch(err => reject(err));
  });
}

export function downloadKPIByProjectId(bearer: string, projectId: number): Promise<string> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/KpiReport/GetByProject',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      params: {
        projectId,
      },
    })
      .then((response: AxiosResponse) => {
        const { data } = response;
        resolve(data);
      })
      .catch(err => reject(err));
  });
}

export function downloadKPI(bearer: string, methodologyId: number): Promise<string> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/KpiReport/GetByMethodology',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      params: {
        methodologyId,
      },
    })
      .then((response: AxiosResponse) => {
        const { data } = response;
        resolve(data);
      })
      .catch(err => reject(err));
  });
}

export function downloadMultipleKPIs(bearer: string, methodologyIds: number[]): Promise<string[]> {
  return new Promise((resolve, reject) => {
    Promise.all(methodologyIds.map((methodologyId: number) => downloadKPIExcel(bearer, methodologyId)))
      .then((response: string[]) => {
        resolve(response);
      })
      .catch(err => reject(err));
  });
}

export function upsertCommunityReport(
  bearer: string,
  communityId: number,
  report: Report,
): Promise<{ communityId: number; report: Report }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'POST',
      url: '/api/CommunityDashboard/SaveReportDefinition',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      // TODO: replace tables[0] with all tables[i]
      data: {
        id: report.id,
        communityId,
        title: report.title,
        table: report.type,
        keyColumn: report.tables[0].keyColumn,
        valueColumn: report.tables[0].valueColumn,
        aggregationFunction: report.tables[0].aggregationFunction,
        chartType: report.chartType,
      },
    })
      .then((response: AxiosResponse) => {
        resolve({
          communityId,
          report: {
            ...report,
            ...response.data,
            data: undefined,
          },
        });
      })
      .catch(err => reject(err));
  });
}

export function fetchCommunityReports(
  bearer: string,
  communityId: number,
): Promise<{ communityId: number; reports: Report[] }> {
  const groupReportsByParentId = (reportDefinitions: ReportDefinition[]): Report[] => {
    const groups: { [id: string]: Report } = reportDefinitions.reduce(
      (acc: { [id: string]: Report }, report: ReportDefinition) => {
        // if no parentReportId is present, then the report is the parent
        const groupId = (report.parentReportId && report.parentReportId !== 0 && report.parentReportId) || report.id;
        const existingTables = (acc[groupId] && acc[groupId].tables) || [];
        const newTables = [
          ...existingTables,
          {
            keyColumn: report.keyColumn,
            keyMinValue: report.keyMinValue,
            keyMaxValue: report.keyMaxValue,
            keyRename: report.keyRename,
            valueColumn: report.valueColumn,
            deltaValues: report.deltaValues,
            aggregationFunction: report.aggregationFunction,
          },
        ];

        if (report.parentReportId && report.parentReportId !== 0) {
          // child report
          return {
            ...acc,
            [groupId]: {
              ...acc[groupId],
              tables: newTables,
            },
          };
        }

        // parent report
        return {
          ...acc,
          [groupId]: {
            id: groupId,
            title: report.title,
            type: report.type,
            chartType: report.chartType,
            tables: newTables,
          },
        };
      },
      {},
    );
    return Object.keys(groups).map((groupName: string) => {
      return groups[groupName];
    });
  };

  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/CommunityDashboard/ReportDefinitionList',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      params: {
        communityId,
      },
    })
      .then((response: AxiosResponse) => {
        const { data } = response;
        resolve({
          communityId,
          reports: groupReportsByParentId([
            ...data.map((r: any) => ({
              ...r,
              type: r.table,
            })),
            ...getTemplateReports(),
          ]),
        });
      })
      .catch(err => reject(err));
  });
}

export function fetchMarketExpertise(bearer: string, communityId: number): Promise<Country[]> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: `/api/Community/${communityId}/market-expertise`,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then((response: AxiosResponse) => {
        resolve(response.data);
      })
      .catch(error => reject(error));
  });
}

export function fetchTeamProjectsByCommunity(
  bearer: string,
  communityId: number,
): Promise<{ id: number; name: string; logoImage: string }[]> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: `/api/Community/${communityId}/projects-i-belong-admin-circle`,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then((response: AxiosResponse) => {
        resolve(response.data);
      })
      .catch(error => reject(error));
  });
}

export function deleteRequest(
  bearer: string,
  entityType: string,
  entityId: number,
  request: Request,
): Promise<{ entityType: string; entityId: number; request: Request }> {
  return new Promise((resolve, reject) => {
    const resourcesToDelete = request.requestResources.map((r: { id: number }) => r.id);
    deleteResources(resourcesToDelete, request.projectId, bearer, false).then(deleteResponse => {
      ai({
        method: 'DELETE',
        url: `/api/Request/${request.id}`,
        headers: {
          Authorization: `Bearer ${bearer}`,
        },
      })
        .then((response: AxiosResponse) => {
          resolve({
            entityType,
            entityId,
            request,
          });
        })
        .catch(error => reject(error));
    });
  });
}

export function closeRequest(bearer: string, requestId: number): Promise<{ requestId: number }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'PUT',
      url: `/api/Request/${requestId}/Close/`,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then((response: AxiosResponse) => {
        resolve({ requestId });
      })
      .catch(error => reject(error));
  });
}

export function fetchAssignMentorProjectList(
  bearer: string,
  communityId: number,
  userId: number,
  projectName: string,
  take: number,
  skip: number,
  circleName: string = 'Mentors',
): Promise<{ id: number; name: string; logoImage: string }[]> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: `/api/Project/GetProjectListToAssignUserToCircle`,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      params: {
        communityId,
        userId,
        circleName,
        projectName,
        take,
        skip,
      },
    })
      .then((response: AxiosResponse) => {
        const data = response.data;
        resolve(data);
      })
      .catch(err => reject(err));
  });
}

export function assignUserToProjects(
  bearer: string,
  userId: number,
  projectIds: number[],
  circleName: string = 'Mentors',
): Promise<null> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'POST',
      url: `/api/Project/AddUserToCircles`,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      data: {
        userId,
        projectIds,
        circleName,
      },
    })
      .then(() => {
        resolve(null);
      })
      .catch(err => reject(err));
  });
}

export function fetchCommunityMembersByCircle(
  bearer: string,
  communityId: number,
  circleId: number,
  skip: number,
  take: number,
): Promise<{
  communityId: number;
  circleId: number;
  skip: number;
  take: number;
  members: Member[];
}> {
  return new Promise((resolve, reject) => {
    fetchCommunityMembers(bearer, communityId, { orderBy: 1 }, { communityCircleId: circleId }, skip, take, false).then(
      (response: { list: CommunityMembers }) => {
        resolve({
          communityId,
          circleId,
          skip,
          take,
          members: response.list.members || [],
        });
      },
    );
  });
}

export const fetchProjectFiltersById = (bearer: string, communityId: number): AxiosPromise<CommunityProjectsFilters> =>
  ai({
    method: 'GET',
    url: `api/Community/${communityId}/ProjectFilters`,
    headers: buildHeaders(bearer),
  });

export const fetchCommunityMembersFiltersById = (
  bearer: string,
  communityId: number,
): AxiosPromise<CommunityMembersFilters> =>
  ai({
    method: 'GET',
    url: `api/Community/${communityId}/MemberFilters`,
    headers: buildHeaders(bearer),
  });

export const fetchCommunityRequestsFilter = (
  bearer: string,
  communityId: number,
): AxiosPromise<CommunityRequestsFilters> =>
  new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: `api/Community/${communityId}/requests/filters`,
      headers: buildHeaders(bearer),
    })
      .then(response => {
        const filterData = response.data;
        const industries = customSortArrayOfObjects(filterData.industries, 'name', GlobalAllOptions.ALL);
        const countries = customSortArrayOfObjects(filterData.countries, 'name', GlobalAllOptions.GLOBAL);
        resolve({ ...response, data: { ...filterData, industries, countries } });
      })
      .catch(err => reject(err));
  });

export const editCommunityTags = (
  bearer: string,
  communityId: number,
  tagsList: Tag[],
  tagType: TagType,
): Promise<{ communityId: number; tagsList: Tag[]; tagType: TagType }> =>
  new Promise((resolve, reject) => {
    ai({
      method: 'POST',
      url: `/api/${tagType}/${communityId}/bulk/Tag`,
      headers: buildHeaders(bearer),
      data: tagsList,
    })
      .then(data => resolve({ communityId, tagsList, tagType }))
      .catch(err => reject(err));
  });

export const deleteCommunityTags = (
  bearer: string,
  communityId: number,
  tagsList: string[],
  tagType: TagType,
): Promise<{ communityId: number; tagsList: string[]; tagType: TagType }> =>
  new Promise((resolve, reject) => {
    ai({
      method: 'DELETE',
      url: `/api/${tagType}/${communityId}/bulk/Tag`,
      headers: buildHeaders(bearer),
      data: tagsList,
    })
      .then(data => resolve({ communityId, tagsList, tagType }))
      .catch(err => reject(err));
  });

export function editCommunityProjectTags(
  bearer: string,
  communityId: number,
  tagsList: Tag[],
): Promise<{ communityId: number; tagsList: Tag[] }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'POST',
      url: `/api/Project/${communityId}/bulk/Tag`,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      data: tagsList,
    })
      .then(data => {
        resolve({ communityId, tagsList });
      })
      .catch(err => reject(err));
  });
}

export function deleteCommunityProjectTags(
  bearer: string,
  communityId: number,
  tags: string[],
): Promise<{ communityId: number; tags: string[] }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'DELETE',
      url: `/api/Project/${communityId}/bulk/Tag`,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      data: tags,
    })
      .then(data => {
        resolve({ communityId, tags });
      })
      .catch(err => reject(err));
  });
}

export function fetchCommunityMembersTags(
  bearer: string,
  communityId: number,
): Promise<{ communityId: number; tags: string[] }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/Tag/GetUserTagNameListByCommunity',
      params: {
        communityId,
      },
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then((response: AxiosResponse) => {
        resolve({
          communityId,
          tags: response.data,
        });
      })
      .catch(err => reject(err));
  });
}

export function saveCommunityMembersTags(
  bearer: string,
  communityId: number,
  userId: number,
  userCommunityId: number,
  userTags: string[],
): Promise<{ communityId: number; userId: number; userCommunityId: number; userTags: string[] }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'POST',
      url: '/api/User/SaveTags',
      data: {
        communityId,
        userId,
        userCommunityId,
        userTags,
      },
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then(response => {
        console.log('Response:', response); // This should log the payload data as a JavaScript object
        resolve({
          communityId,
          userId,
          userCommunityId,
          userTags,
        });
      })
      .catch(err => reject(err));
  });
}

export default {
  fetchCommunities,
  fetchCommunityDetails,
  fetchCommunitySignupDetails,
  fetchResourcesTags,
  fetchCommunityResources,
  createResource,
  createResources,
  deleteResource,
  fetchCommunityRequests,
  updateRequestTagsById,
  fetchRequestsTags,
  fetchCommunityDiscussions,
  acceptProject,
  denyProject,
  fetchCommunityProjects,
  fetchCommunityCircles,
  saveCommunityCircles,
  saveCommunityCircle,
  deleteCommunityCircle,
  editCommunityCircle,
  fetchCommunityMembersCountries,
  fetchCommunityProjectsCountries,
  fetchCommunityProjectsCategories,
  fetchCommunityProjectsStages,
  fetchCommunityProjectsSDGs,
  fetchCommunityProjectsTags,
  saveCommunityProjectsTags,
  fetchProjectTags,
  fetchCommunityMembersSkills,
  fetchCommunityMembersInterests,
  fetchCommunityMembers,
  respondCommunityJoinRequest,
  removeCommunityInvitation,
  inviteCommunityMembers,
  fetchAllCommunityRequests,
  updateCommunityInfo,
  downloadStatistics,
  fetchResourceCircles,
  setAllowTasks,
  fetchProjectsAwaitingFeedback,
  fetchProjectsNextParagraphs,
  removeUserFromCommunity,
  fetchCommunityProgressTracking,
  acceptCommunityInvitation,
  declineCommunityInvitation,
  fetchCommunityCalendarEvents,
  fetchCommunityCalendarEventDetails,
  upsertCommunityCalendarEvent,
  deleteCommunityCalendarEvent,
  searchCommunityProgressTracking,
  fetchKPI,
  deleteKPI,
  upsertKPI,
  downloadKPI,
  downloadMultipleKPIs,
  upsertCommunityReport,
  fetchCommunityReports,
  fetchHistoricalStatistics,
  updateCommunityCircle,
  deleteRequest,
  closeRequest,
  fetchCommunityFilteringSkills,
  fetchAssignMentorProjectList,
  assignUserToProjects,
  fetchCommunityMembersPendingByUser,
  fetchCommunityMembersByCircle,
  fetchMultipleMethodologyKPIs,
  fetchProjectFiltersById,
  deleteStatisticsReport,
  editCommunityTags,
  deleteCommunityTags,
  editCommunityProjectTags,
  deleteCommunityProjectTags,
  fetchCommunityMembersTags,
  saveCommunityMembersTags,
  fetchCommunityMembersFiltersById,
};
