import {
  CommentType,
  Planner,
  PrivacyCircleType,
  NotificationFrequency,
  EventUpdatesType,
  AggregationFunctionsEnum,
  StatsType,
  ChartTypesEnum,
  TipState,
  FormQuestionAnswerType,
  ApplicationStatus,
  UserInvitationPolicy,
  RequestType,
  CalendarProvider,
  MeetingProvider,
} from './enums';
import { Moment } from 'moment';
import { ReactNode } from 'react';
import { ResourceMeta } from 'routes/project/new-request';

export type MentionUser = {
  id: string;
  userId: number;
  name: string;
};
export type OverviewProjectChoice = { projectId: number; tab: string; requestIds?: number[] };
export type Community = {
  id: number;
  logo?: string;
  name?: string;
  shortDescription?: string;
  canEditCommunityInfo?: boolean;
  city?: string;
  canEditResources?: boolean;
  canEditDiscussions?: boolean;
  isMember?: boolean;
  allowTasks: boolean;
  feed?: FeedItem[];
  feedAllLoaded?: boolean;
  circleName?: string;
  circles?: Circle[];
  circleToCirclePermission?: [];
  resourcesCircles?: Circle[];
  discussionCircles?: Circle[];
  isCommunityAdmin?: boolean;
  communityProjectsCountries?: Country[];
  communityProjectsCategories?: ProjectCategory[];
  communityProjectsStages?: ProjectStage[];
  communityProjectsSDGs?: SDG[];
  communityProjectsTags?: string[];
  communityMembersTags?: string[];
  canEditProjectTags?: boolean;
  canSeeProjectOverview?: boolean;
  progressOverview?: CommunityProgressOverview;
  // events?: EventCalendar[];
  progressSearch?: {
    data: CommunityProgressOverview;
    communityId: number;
  };
  developmentStageType?: number;
  externalInvitedMail?: string;
  canProjectsCreateAditionalCirclesToCustomizePrivacy?: boolean;
  canProjectsInviteMembersAndMentorsFromOutside?: boolean;
  canProjectsInviteMembersAndMentorsFromPlatform?: boolean;
  userJoinCommunitySetting?: number;
  isCreateProjectsLocked?: boolean;
  hasLockTags?: boolean;
  mentionUsers?: MentionUser[];
  isHomeTabVisible?: boolean;
  isProjectsTabVisible?: boolean;
  isForumTabVisible?: boolean;
  isRequestsTabVisible?: boolean;
  isMembersTabVisible?: boolean;
  isEventsTabVisible?: boolean;
  isAboutTabVisible?: boolean;
  isResourcesTabVisible?: boolean;
  isApplicationsTabVisible?: boolean;
  canViewApplicationsTab?: boolean;
  applicationDefinitions?: ApplicationDefinition[];
  forms?: Form[];
  projectsUserInvitationPolicy?: UserInvitationPolicy;
  customLiterals?: Literals;
  tabs?: CommunityTab[];
  tabsEntities?: TabEntity[];
  tabsEntitiesComments?: TabEntityComment[];
  tabsEntitiesCircles?: TabEntityCircle[];
  projectForm?: Form;
  isSDGsVisible?: boolean;
  isUserSDGsVisible?: boolean;
  isWebSiteUrlVisible?: boolean;
  isFacebookUrlVisible?: boolean;
  isLinkedinUrlVisible?: boolean;
  isDevelopmentStageVisible?: boolean;
  communitySkillCategories?: CommunitySkillCategory[];
  permissions?: CirclePermissions;
  isCalendarSynchronized?: boolean;
  synchronizedCalendarAccount?: string | null;
  synchronizedCalendarType?: CalendarProvider | null;
  hasMeetingProvider?: boolean;
  meetingProviderType?: MeetingProvider | null;
  meetingProviderAccount?: string | null;
  projectFilters?: CommunityProjectsFilters;
  memberFilters?: CommunityMembersFilters;
  requestFilters?: CommunityRequestsFilters;
  applicationDefinitionFilters?: ApplicationFilters;
  entityFilters?: CommunityEntityTabFilters;
  termsAndConditionLink?: string;
  hasLandingPage?: boolean;
} & CommunityDetails &
  CommunityMembers &
  CommunityResources &
  CommunityDiscussions &
  CommunityEvents &
  CommunityReports &
  CommunityRequests;

export type CommunityDetails = {
  id: number;
  methodologies?: Methodology[];
  description?: string;
  coverImage?: string;
  website?: string;
  communityTags?: string[];
  countryId?: number;
  generalSettingsHasLockTags?: boolean;
  hideParagraphsUntilDate?: boolean;
  defaultProjectFilterTag?: boolean;
  menuLogoImage?: string;
  projectsAwaitingFeedback?: ProjectAwaitingFeedback[];
  projectsNextParagraphs?: ProjectNextParagraphs[];
  projectsCount?: number;
  totalProjectsCount?: number;
  canCreateProjects?: boolean;
  canAccessToOverviewProject?: boolean;
  canApproveParagraph?: boolean;
  canInviteToProjects?: boolean;
  projectsTabLiteral?: string;
  businessModelTabLiteral?: string;
};

export type CommunityEvents = {
  id: number;
  canEditEvents?: boolean;
  events?: CalendarEvent[];
};

export type CommunityReports = {
  reports: Report[];
};

export enum MeetingOriginType {
  MembersOneOnOne = 0,
  EventsCommunity = 1,
  ProjectsOneToMany = 2,
  Requests = 3,
  CoachingSessions = 4,
}

export type CalendarEvent = {
  id: string;
  summary: string;
  description: string;
  startDate: string;
  startTime: string;
  endDate: string;
  endTime: string;
  happened?: boolean;
  attendeeUsers?: EventAttendee[];
  attendeesCount?: number;
  sendUpdates?: EventUpdatesType;
  location?: string;
  isUserInAttendeeList: boolean;
  userCanEdit?: boolean;
  createConference?: boolean;
  meetingOriginType?: MeetingOriginType;
};

export type EventAttendee = {
  id: number;
  name?: string;
  email?: string;
  photoUrl?: string;
  responseStatus?: string;
};
export type EventAttendance = {
  [details: string]: {
    count: number;
    users: EventAttendee[];
  };
};

export type CommunityMembers = {
  id: number;
  countries?: Country[];
  skills?: Skill[];
  interests?: Interest[];
  circles?: Circle[];
  members?: Member[];
  pendingUsers?: Member[];
  pendingByUsers?: Member[];
  totalMembersCount?: number;
  membersCount?: number;
};

export type CommunityResources = {
  id: number;
  canEditResources?: boolean;
  resourcesTags?: string[];
  resources?: Resource[];
  resourcesCount?: number;
  totalResourcesCount?: number;
};

export type CommunityDiscussions = {
  id: number;
  discussionTags?: string[];
  discussions?: Discussion[];
  communityDiscussions?: Discussion[];
  discussionsCount?: number;
  totalDiscussionsCount?: number;
};

export type CommunityRequests = {
  id: number;
  requestsTags?: string[];
  requestSkills?: RequestFilteringSkill[];
  canCreateRequests?: boolean;
  openRequests?: Request[];
  allRequests?: RequestStatus[];
  requestsCount?: number;
  totalRequestsCount?: number;
};

export type RequestStatus = {
  id: string;
  postDate: string;
  projectName: string;
  projectId: string;
  name: string;
  entrepreneurName: string;
  advisorName: string;
  advisorComment: string;
  advisorNote: string;
  advisorId: string;
  advisorFeedbackDatetime: string;
  advisorInvitedDateTime: string;
  advisorAssignedDateTime: string;
  entrepreneurComment: string;
  entrepreneurFeedbackDatetime: string;
  eventDateTimeStart: string;
  eventId: string;
  skills: string;
  requestStatus: string;
  advisorRating: string;
  entrepreneurRating: string;
  resolvedDate: string;
  sessionType: string;
};

export type RequestStatusFilters = {
  advisors: {
    id: number;
    firstName: string;
    lastName: string;
  }[];
  startups: {
    id: number;
    name: string;
    logoImage: string;
  }[];
  skills: RequestFilteringSkill[];
};

export type RequestFilteringSkill = {
  id: number;
  name: string;
};

export type ProjectProgressOverview = {
  projectId: number;
  projectName: string;
  projectLogo: string;
  progress: number;
  paragraphsWithoutComments?: number;
};

export type CommunityProgressOverview = {
  nonDatedTasks: ProjectProgressOverview[];
  slow: ProjectProgressOverview[];
  regular: ProjectProgressOverview[];
};

export type Methodology = {
  id: number;
  name: string;
  description: string | null;
  videoUrl: string | null;
  sections: Section[];
  planner: {
    tasks: Task[];
    plannerType: Planner;
  };
  kpiDefinitions?: KpiDefinition[];
  isRetroactive?: boolean;
  usingProjects?: { projectId: number; projectName: string }[];
  communityId?: number;
  isActive: boolean;
  isDeleted?: boolean;
};

export type Section = {
  id: number;
  name: string;
  order: number;
  logo: string;
  methodologyId: number;
  isLean: boolean;
  isActive?: boolean;
  isStagegated?: boolean;
  methodology?: any;
  assignments: Assignment[];
};

export type MethodologyContentCircle = {
  id?: number;
  communityCircleId: number;
  methodologyParagraph?: BusinessModelAssignment;
  methodologyParagraphId?: number;
  projectCircleType?: 1 | 2 | 3;
};

export type Assignment = {
  id: number;
  name: string;
  order: number;
  description: string;
  details: string;
  template: string;
  sectionId: number;
  methodologyId: number;
  sectionName?: string;
  feedbackText?: string;
  wikiLinks?: string;
  wikiProblem?: string;
  isHillary: boolean;
  inTutorial?: boolean;
  taskSendReminder: boolean;
  privacyLevel?: number;
  methodologyContentCircleList?: MethodologyContentCircle[];
};

export type Task = {
  id: number;
  startDate: string;
  publishDate: string;
  endDate: string;
  assignmentId: number;
  order: number;
  taskSendReminder: boolean;
};

export type ProjectBusinessModel = {
  id: number;
  projectId: number;
  methodologyId: number;
  methodologyName: string;
  taskUnlockRule?: Planner;
};

export type BusinessModel = {
  canEditBusinessModel: boolean;
  sections: BusinessModelSection[];
  customPrivacyCircles: Circle[];
};

export type BusinessModelSection = {
  id: number;
  name: string;
  order: number;
  logo: string;
  isLean: boolean;
  assignments: BusinessModelAssignment[];
  isStagegated?: boolean;
  isStagegateLocked?: boolean;
};

export type BusinessModelAssignment = {
  id: number;
  name: string;
  order: number;
  isActive: boolean;
  privacyLevel: number;
  teamCanChoosePrivacy?: boolean;
  lastUpdated: string;
  content: string;
  sectionId: number;
  sectionName: string;
  methodologyParagraphId: number;
  description: string;
  details: string;
  template: string;
  feedbackText?: string;
  wikiLinks?: string;
  wikiProblem?: string;
  isHillary?: boolean;
  inTutorial?: boolean;
  comments: Comment[];
  canView: boolean;
  canViewByCalendarPlanning?: boolean | undefined;
  canViewBySequentialPlanning?: boolean | undefined;
  dueTask: ProjectDueTask;
  customPrivacyCircles?: Circle[];
  isApproved?: boolean;
};

export type SaveParagraph = {
  id: number;
  sectionId: number;
  name: string;
  methodologyParagraphId: number;
  content: string;
  isActive: boolean;
  privacyLevel: number;
  isDraftTask: boolean;
  isLockedCommentBusinessModel: boolean;
};

export type ProjectTask = {
  id: number;
  commentCount: number;
  methodologySectionId: number;
  paragraph: BusinessModelAssignment;
  order: number;
  startDate: string;
  publishDate: string;
  endDate: string;
  status: number;
};

export type ProjectDueTask = {
  isDue: boolean;
  isLastDay: boolean;
  isPublished: boolean;
  text: string;
  exists: boolean;
  canSee: boolean;
};

export type ProjectTaskParagraph = {
  id: number;
  name: string;
  privacyLevel: number;
  content: string;
  commentCount: number;
};

export type Member = {
  userTags: string[];
  id: number;
  name: string;
  photo: string;
  email: string;
  occupation: string;
  country: Country;
  skills: Skill[];
  interests: Interest[];
  tags: string[];
  circle: Circle;
  isFollowed: boolean;
};

export type Country = {
  id: number;
  name: string;
  code?: string;
};

export type Interest = {
  id: number;
  name: string;
};

export type Skill = {
  id: number;
  name: string;
  isActive?: boolean;
  popUpText?: string;
};

export type SkillCategory = {
  id: number;
  isActive: boolean;
  name: string;
  skills: Skill[];
  img: string;
};

export type CommunitySkillCategory = {
  id: number;
  skillCategoryId: number;
  communityId: number;
  isActive: boolean;
  name: string;
};

export type Resource = {
  id: number;
  tags: string[];
  image?: string;
  lastUpdated?: string;
  postDate?: string;
  name: string;
  description: string;
  resourceUrl?: string;
  file?: File | string;
  extension: string;
  privacyLevel: number;
  circles: Circle[];
  resourceType?: number;
  communityId?: number;
  paragraphId?: number;
  usedByRequest?: boolean;
  icon?: string;
  iconFile?: File | string;
  communityResourceCircleTags?: string[];
  // URGENT TODO: fix tags issue on resources
  // tags?: Tag[];
};

export type Discussion = {
  id: number;
  projectId?: number;
  communityId?: number;
  name: string;
  content: string;
  commentCount: number;
  viewCount: number;
  image: string;
  postDate: string;
  lastUpdated: string;
  deadlineDate: string;
  deadline: number;
  daysLeft: number;
  paragraphId: number;
  isActive: boolean;
  userId: number;
  authorName: string;
  authorPhoto: string;
  discussionTags: string[];
  discussionSkillCategories: string[];
  isRelatedWithMethodology: boolean;
  readOnly?: boolean;
  isArchived: boolean;
  allowLockComments: boolean;
  isLockedCommentDiscussions: boolean;
  privacyLevel: number;
  privacyCircles?: Circle[];
  projectLogoImage?: string;
  projectName?: string;
  comments: Comment[];
  isFollowing: boolean;
  followersCount?: number;
  discussionUserFollows?: DiscussionUserFollows[];
  discussionPrivacyCircles?: Circle[];
  communityMembersTags: string[];
  circleTags?: string[];
};

export type InvitedAdvisor = {
  id: number;
  name: string;
  photo: string;
  status: number;
  eventDate?: Moment;
  eventId?: string;
};

export type SaveRequest = {
  id?: number;
  name: string;
  description: string;
  privacyLevel: number;
  privacyCircleIds: number[];
  skillIds: number[];
  marketExpertiseIds: number[];
  projectResourceIds: number[];
  alreadyUploadedResources: Resource[];
  resourcesToUpload: ResourceMeta[];
  resourcesToDelete: number[];
  requestType?: RequestType;
  requestIndustryIds: number[];
};

export type SaveCoachingSession = {
  id?: number;
  summary: string;
  description: string;
  start: string;
  end: string;
  attendeeUserIds: number[];
  projectId: number;
  createConference: boolean;
  location: string;
};

export type Request = {
  id: number;
  name: string;
  description: string;
  userId: number;
  userFirstName: string;
  userLastName: string;
  userMail: string;
  userPhoto: string;
  requestStatus: number;
  tags: string[];
  projectId: number;
  projectLogoUrl: string;
  projectName: string;
  commentCount: number;
  matchMakingScore: number;
  recommended: boolean;
  canInviteAdvisor: boolean;
  details?: RequestDetails;
  invitedAdvisors: InvitedAdvisor[];
  requestType: RequestType;
  requestResources: {
    url: string;
    name: string;
    id: number;
  }[];
  requestIndustryIds: number[];
  calendarEventId?: string;
  notifiedUsers: RequestNotifiedUsers[];
};

export type RequestNotifiedUsers = {
  name: string;
  id: number;
  photo: string;
};

export type SessionAttendee = {
  name: string;
  id: number;
};

export type RequestDetails = {
  postDate: string;
  lastUpdated: string;
  privacyLevel: number;
  requestPrivacyCircles: RequestCircle[];
  requestSkills: RequestSkill[];
  requestMarketExpertises: RequestCountry[];
  comments: Comment[];
  awaitingAdvisorFeedback: boolean;
  awaitingEntrepreneurFeedback: boolean;
  advisorId?: number;
  startDate?: string;
  sessionLink?: string;
  attendees: SessionAttendee[];
  entrepreneurId?: number;
};

export type RequestCountry = {
  countryId: number;
  countryName: string;
};

export type RequestCircle = {
  requestId: number;
  circleId: number;
};

export type RequestSkill = {
  skillId: number;
  skillName: string;
};

export type ProjectAwaitingFeedback = {
  id: number;
  name: string;
  description: string;
  logo: string;
  sections: {
    id: number;
    name: string;
    logo: string;
    paragraphs: {
      id: number;
      name: string;
    }[];
  }[];
};

export type ProjectNextParagraphs = {
  id: number;
  name: string;
  logo: string;
  sections: {
    id: number;
    name: string;
    logo: string;
    assignments: {
      id: number;
      name: string;
    }[];
  }[];
  nextParagraph: {
    id: number;
    name: string;
    description: string;
    taskDateStart: string;
  };
};

export type Project = {
  id: number;
  isProjectTeamMember?: boolean;
  isProjectAdmin?: boolean;
  isMember?: boolean;
  communityIds: number[];
  name: string;
  creationDate?: string;
  description: string;
  logo: string;
  cover: string;
  SDGs: SDG[];
  tags: string[];
  resourcesTags?: string[];
  country: string;
  city: string;
  category: string;
  stage: string;
  isPending?: boolean;
  isFollowing: boolean;
  members?: Member[];
  pendingByUsers?: Member[];
  pendingApplyToTeam?: Member[];
  resources?: Resource[];
  canEditDiscussions?: boolean;
  canEditPrivacy: boolean;
  canEditResources?: boolean;
  discussions?: Discussion[];
  circles?: Circle[];
  feed?: FeedItem[];
  feedAllLoaded?: boolean;
  discussionCircles?: Circle[];
  resourcesCircles?: Circle[];
  discussionTags?: string[];
  businessModel: BusinessModel;
  projectTasks?: ProjectTask[];
  taskUnlockRule?: Planner;
  canEditProjectInfo?: boolean;
  websiteUrl?: string;
  twitterUrl?: string;
  facebookUrl?: string;
  googleUrl?: string;
  linkedInUrl?: string;
  flickrUrl?: string;
  categoryId?: number;
  unGoalId?: number;
  projectModel?: string;
  projectPrivacy?: number;
  taggedEntityId?: number;
  allowLockComments?: boolean;
  countryId?: number;
  canInviteUser?: boolean;
  discussionCount?: number;
  networkCount?: number;
  viewCount?: number;
  progressDetails?: ProjectProgressDetails;
  mainCommunityId: number;
  kpiValues?: KpiValue[];
  requests?: Request[];
  requestsCount?: number;
  totalRequestsCount?: number;
  requestsTags?: string[];
  requestSkills?: RequestFilteringSkill[];
  canCreateRequests?: boolean;
  hasPendingRequestFeedback?: boolean;
  requestsHasDefaultPrivacyCircle?: boolean;
  canInviteMembersAndMentorsFromOutside?: boolean;
  canInviteMembersAndMentorsFromPlatform?: boolean;
  userInvitationPolicy?: UserInvitationPolicy;
  form?: Form;
  answers?: FormQuestionAnswer[];
  kpis?: KpiDefinition[];
  kpiDefinitions?: KpiDefinition[];
  isCommunityAdmin?: boolean;
  canApproveParagraph?: boolean;
  canEditProjectTeam?: boolean;
  projectBusinessModels?: ProjectBusinessModel[];
  requestFilter?: CommunityRequestsFilters;
  projectCommunityUserCircleTagList?: string[];
  projectCommunityCircleList?: ProjectCircle[];
};

// @ TYPE FOR THE OVERVIEW PROJECT PAGE //
export type ProjectUser = {
  projectCircleId: number;
  isPendingByUser: boolean;
  isPendingByTeam: boolean;
  requestedProjectCircleId: null;
  id: number;
  firstName: string;
  lastName: string;
  mail: string;
  photo: string;
  occupation: string;
  biography: string;
  commentCount: number;
  likeCount: number;
  followedUsersCount: number;
  activationCode: string;
  activationCodeSent: boolean;
  isProfileWizardDone: boolean;
  countryId: number;
  country: null;
  city: string;
  isBanned: boolean;
  isActive: boolean;
  invitationUrl: null;
  language: string;
  registrationDate: string;
  lastLoginDate: string;
  notificationEnabled: boolean;
  primaryCommunityId: number;
  hasPendingRequests: boolean;
  userInterests: null;
  userSkills: null;
  userSocial: null;
  userMarketsExpertises: null;
  userSustainableDevelopmentsGoals: null;
  termsAndConditionsVersion: number;
  photoUrl: string;
  facebookUrl: null;
  twitterUrl: null;
  googleUrl: null;
  linkedInUrl: null;
  isFollowedByMe: boolean;
};

export type ProjectCircle = {
  projectId: number;
  project: null;
  name: string;
  isTeamCircle: boolean;
  isMentorsCircle: boolean;
  isSpecialCircle: boolean;
  id: number;
  communityCircleId: number;
};
export type ProjectRequestFeedbackCount = {
  projectId: number;
  requestFeedbackCount: number;
};
export type ProjectOverview = {
  projectId: number;
  projectName: string;
  logoImage: string;
  progress: number;
  tags: string[];
  projectCircles: ProjectCircle[];
  projectUsers: ProjectUser[];
  lastUpdate: string | null;
  stage: string;
  coachingSessions: number;
  kpi: number;
  mentorCircleId?: number;
};
export type ProjectSearchOverview = {
  projectId: number;
  projectName: string;
  logoImage: string;
  progress: number;
  tags: string[];
  projectCircles: ProjectCircle[];
  projectUsers: ProjectUser[];
  lastUpdate: string | null;
  stage: string;
  coachingSessions: number;
  kpi: number;
};
// @ TYPE FOR THE OVERVIEW PROJECT PAGE //

export type ProjectCategory = {
  id: number;
  name: string;
  isAddedByUser: boolean;
};

export type MembersCategory = {
  id: number;
  name: string;
  isAddedByUser: boolean;
};

export type ProjectStage = {
  key: string;
  value: string;
};

export type ProjectProgressDetails = {
  progress: number;
  sections: ProjectProgressSection[];
};

export type ProjectProgressSection = {
  sectionId: number;
  sectionName: string;
  sectionIcon: string;
  sectionIsStagegateLocked?: boolean;
  isStagegateLocked?: boolean;
  paragraphs: ProjectProgressParagraph[];
};

export type ProjectProgressParagraph = {
  paragraphId: number;
  paragraphName: string;
  isPublished: boolean;
  isApproved: boolean;
  approvedTime: string | null;
  submitedTime: string | null;
  taskDateStart?: string;
  taskDateEnd?: string;
  taskTimestampStart: number;
  taskTimestampEnd: number;
  isDue: boolean;
  outlineDot: boolean;
  continueLine: boolean;
  week: number;
  involvedUsers: { userId: number; userPhoto: string; userFirstName: string; userLastName: string }[];
};

export type NewProject = {
  communityId: number;
  name: string;
  description: string;
  categoryId: number;
  developmentStage: string;
  methodologyId: number;
  SDGs: number[];
  countryId: number;
  city: string;
  websiteUrl: string;
  facebookUrl: string;
  linkedInUrl: string;
  logo?: string;
  cover?: string;
  form?: Form;
  answers?: FormQuestionAnswer[];
};

export type SDG = {
  id: number;
  name: string;
  iconName: string;
};

export type MyStuffItem = {
  id: number;
  name: string;
  description: string;
  logo: string;
  logoImage?: string;
  owner: boolean;
  communityIds: number[];
  circleName?: string;
};

export type MyStuffItemDiscussions = {
  id: number;
  entityType: 'community' | 'project';
  entityId: number;
  logoImage: string;
  name: string;
};

export type MyStuffItemRequests = {
  id: number;
  name: string;
  requestStatus: number;
  resourceUrl: string;
  projectLogoImage: string;
  projectId: number;
  reviews: MyStuffReview[];
};

type Rating = {
  userId: number;
  userName: string;
  userPhoto: string;
  rating: number;
  comment: string;
  date: Moment;
};

export type MyStuffReview = {
  id: string;
  advisorRating: Rating;
  entrepreneurRating: Rating;
};

export type MyStuff = {
  communities: MyStuffItem[];
  projects: MyStuffItem[];
  followedProjects: MyStuffItem[];
  requests: MyStuffItemRequests[];
  followedDiscussions: MyStuffItem[];
};

export type NotificationSetting = {
  id: number;
  description: string;
  notificationType: string;
  category: string;
  notificationFrequency: NotificationFrequency;
};

export type User = {
  rawUser?: any;
  id: number;
  name: string;
  firstName?: string;
  lastName?: string;
  photo: string;
  email: string;
  admin: boolean;
  active: boolean;
  banned: boolean;
  occupation: string;
  primaryCommunityId: number;
  profileWizardDone: boolean;
  countryId: number;
  city: string;
  language: string;
  notificationActive: boolean;
  registrationDate: string;
  lastLogin: string;
  linkedInUrl?: string;
  ownedProjects: MyStuffItem[];
  followedProjects: MyStuffItem[];
  communities: MyStuffItem[];
  company: string;
  profile: {
    biography: string;
    interests: Interest[];
    skills: Skill[];
    marketExpertises: Country[];
    sustainableDevelopmentGoals: SDG[];
  };
  settings: {
    notifications: {
      enabled: boolean;
      list: NotificationSetting[];
    };
  };
  requests: MyStuffItemRequests[];
  pendingRequestIds?: number[];
  isCalendarSynchronized?: boolean;
  synchronizedCalendarAccount?: string;
  synchronizedCalendarType?: number; // 0 = Google, 1 = Outlook
  hasMeetingProvider?: boolean;
  meetingProviderAccount?: string;
  meetingProviderType?: 0 | 1; // 0 = MS Teams, 1 = Outlook
  countryName?: string;
  redirectAfterFirstLogin?: string | null;
};

export type Session = {
  bearer: string;
  refresh: string;
};

export type FeedItem = CommunityPost | DiscussionPost | ProjectPost | ProgressUpdate;

export type Author = {
  id: number;
  name: string;
  photo: string;
  occupation: string;
};

export type Comment = {
  id: number;
  author: Author;
  date: string;
  content: string;
  parentId: number;
  likeCount: number;
  isLiked: boolean;
  comments: Comment[];
  canUserEdit?: boolean;
  canUserRemove?: boolean;
  userLikesComment?: boolean;
  userDislikesComment?: boolean;
  skipExpandablePost?: boolean;
};

export type DiscussionUserFollows = {
  firstName: string;
  id: number;
  lastName: string;
  photo: string;
};

export type CommunityPost = {
  id: number;
  type: CommentType;
  date: string;
  commentCount: number;
  likeCount: number;
  isLiked: boolean;
  content: string;
  author: Author;
  comments: Comment[];
  isPinned: boolean;
};

export type DiscussionPost = {
  id: number;
  type: CommentType;
  date: string;
  commentCount: number;
  title: string;
  content: string;
  image: string | null;
  author: Author;
  comments: Comment[];
  project: {
    id: number;
    name: string;
    logo: string;
  };
};

export type ProjectPost = {
  id: number;
  type: CommentType;
  date: string;
  commentCount: number;
  likeCount: number;
  isLiked: boolean;
  content: string;
  author: Author;
  comments: Comment[];
  project: {
    id: number;
    name: string;
    logo: string;
  };
};

export type ProgressUpdate = {
  id: number;
  type: CommentType;
  date: string;
  commentCount: number;
  likeCount: number;
  isLiked: boolean;
  content: string;
  section: string;
  title: string;
  comments: Comment[];
  project: {
    id: number;
    name: string;
    logo: string;
  };
};

export type Notification = {
  id: number;
  content: string;
  isRead: boolean;
  date: string;
  interestLink: string;
};

export type CirclePermissions = {
  permissionRequestCanViewAllAndInviteAdvisor?: boolean;
  permissionCanApproveParagraph?: boolean;
  permissionCanInviteToProjects?: boolean;
  permissionCanAccessToOverviewProject?: boolean;
  permissionProjectCanCreate?: boolean;
  permissionCanBeInvitedAsCoach?: boolean;
  permissionCanCoachTeamMembers?: boolean;
};

export type Circle = {
  id: number;
  name: string;
  shortName: string;
  admin: boolean;
  type?: CircleType;
  projectId?: number;
  communityId?: number;
  userCount?: number;
  isActive?: boolean;
  eventMembers?: Member[];
} & CirclePermissions;

export type CircleType = {
  name: PrivacyCircleType;
  isProject: boolean;
  isCommunity: boolean;
};

export type Week = {
  on: boolean;
  startDate: Moment | null;
  publishDate: Moment | null;
  endDate: Moment | null;
};

export type Sorting = {
  orderBy?: number | null;
};

export type CommunityRequestsParameters = {
  Take: number;
  Skip: number;
  Name?: string;
  MarketExpertiseId?: string | number;
  skillCategoryIds?: string | number;
  Tags?: string[];
  orderBy?: number;
  NumberOfDaysOld?: string | number;
};

export type Filtering = {
  concept?: string;
  name?: string;
  tags?: string[];
  countryId?: number;
  categoryId?: number;
  stage?: string;
  sustainableDevelopmentGoalsIds?: string;
  sustainableDevelopmentGoals?: number[];
  communityCircleId?: number;
  skillId?: number;
  skillCategoryId?: number;
  interestId?: number;
  NumberOfDaysOld?: number;
  status?: number;
  requestType?: RequestType;
};

export type DropdownItem = {
  key: string;
  value: string | number | boolean;
  text: string;
  img?: string;
};
export type SearchUser = {
  name: string;
  isAlreadyMember: boolean;
  isCommunityMemberForProjectInvite: boolean;
  hasBeenInvited: boolean;
  photo: string;
  photoUrl: string;
  email: string;
  city: string;
  id: number;
  biography?: string;
};

export type KpiValue = {
  id: number;
  name: string;
  projectId: number;
  kpiDefinitionId: number;
  year: number;
  month: number;
  value: number;
};

export type KpiDefinition = {
  id: number;
  methodologyId?: number;
  projectId?: number;
  name: string;
  frequency: number;
  startingYear: number;
  startingMonth: number;
  isBiggerBetter: boolean;
  enabled: boolean;
};

export type projectKpiDefinition = {
  id: number;
  methodologyId: number;
  projectId: number;
  name: string;
  frequency: number;
  startingYear: number;
  startingMonth: number;
  isBiggerBetter: boolean;
  enabled: boolean;
};

export type KPIValue = {
  id: number;
  year: number;
  month: number;
  value: number;
};
export type ProjectKpiDetail = { finished: number; total: number };
export type ProjectKpis = { [projectId: number]: ProjectKpiDetail };

export type KPIList = {
  frequency: number;
  kpiDefinitionAndProjectName: string;
  kpiDefinitionStartYear: number;
  kpiDefinitionStartMonth: number;
  kpiDefinitionId: number;
  kpiValues: KPIValue[];
};

export type JSONReport = {
  frequency: number;
  kpiDefinitionAndProjectName: string;
  kpiDefinitionId: number;
  kpiValues: KPIValue[];
  id: number;
  title: string;
  type: StatsType;
  chartType: ChartTypesEnum;
  tables?: string[];
  data: ReportData;
  disableFillMinimumData: boolean;
};

export type CreateTaskPayload = {
  id?: number;
  assignmentId: number;
  dateStart: string;
  datePublish: string;
  dateEnd: string;
  order?: number;
  taskSendReminder?: boolean;
};

export type Literals = {
  [name: string]: string;
};

export type Report = {
  id: number;
  title: string;
  type: StatsType;
  chartType: ChartTypesEnum;
  tables: {
    keyColumn: string;
    keyMinValue?: number;
    keyMaxValue?: number;
    keyRename?: string;
    valueColumn: string;
    deltaValues?: boolean;
    aggregationFunction: AggregationFunctionsEnum;
  }[];
  data?: ReportData;
  /** if true show the difference between each month instead of the absolute value */
  dataRange?: string;
  YDomain?: {
    min: number;
    max: number;
  };
  disableFillMinimumData?: boolean;
};

export type ReportDataRow = {
  name: string;
  [key: string]: string;
};

export type ReportData = ReportDataRow[];

export type ReportDefinition = {
  id: number;
  title: string;
  type: StatsType;
  chartType: string;
  keyColumn: string;
  keyMinValue?: string;
  keyMaxValue?: string;
  deltaValues?: boolean;
  keyRename?: string;
  valueColumn: string;
  aggregationFunction: string;
  parentReportId?: number;
};

export type KPIValues = {
  id: number;
  year: number;
  month: number;
  value: number;
  week?: number;
};

export type ReportJson = {
  frequency: number;
  kpiDefinitionAndProjectName: string;
  kpiDefinitionId: number;
  kpiValues: KPIValues[];
};

export interface IPrivacyDropdownOption {
  key: string;
  text: string;
  value: number;
}

export type Tips = {
  communityList: TipState;
};

export type SuperPanelCommunityStats = {
  communityId: number;
  communityName: string;
  adminCount: number;
  memberCount: number;
  communityProjectCount: number;
  approvedProjectCount: number;
  approvedAndPublishedProjectCount: number;
  activeUserCount: number;
  commentCount: number;
  resourceCount: number;
  discussionCount: number;
};

export type SuperPanelProjectStats = {
  projectId: number;
  projectName: string;
  projectCountryName: string;
  projectCategoryName: string;
  projectCreationDate: string;
  isProjectCreatedThisWeek: string;
  teamMemberCount: number;
  followersCount: number;
  notEmptyParagraphCount: number;
  discussionCount: number;
  commentCount: number;
  lastUpdated: string;
  updatedCount: number;
  isDisabled: boolean;
  loveCount: number;
  viewCount: number;
  usersCount: number;
  projectDevelopmentStage: string;
};

export type SuperPanelUserStats = {
  userId: number;
  userMail: string;
  userFirstName: string;
  userLastName: string;
  userCountryName: string;
  userIsActive: boolean;
  userLastLoginDate: string;
  userRegistrationDate: string;
  isUserCreatedThisWeek: string;
  projectsAsTeamMemberCount: number;
  projectsAsTeamMemberNames?: string[];
  followedProjectsCount: number;
  followedUsersCount: number;
  followingUsersCount: number;
  accessesCount: number;
  commentCount: number;
  likesEmittedCount: number;
  likesReceivedCount: number;
  externalUserInvitedCount: number;
  messagesSentCount: number;
  userFullName: string;
};

export type ApplicationDefinition = {
  id: number;
  communityId: number;
  name: string;
  description: string;
  startDate: string;
  endDate: string;
  acceptedEmail: string;
  rejectedEmail: string;
  acceptedCircleId: number | null;
  acceptedMethodologyId: number | null;
  createProject: boolean;
  formId: number;
  form?: Form;
  projectForm?: Form;
  projectAnswers?: FormQuestionAnswer[];
  creatorUserId: number;
  applicationDefinitionCriterias: string[];
  applicationDefinitionEvaluators: ApplicationEvaluator[];
  evalutatorCircleIds: number[];
  evaluationRequired: boolean;
  isCreatingNewForm: boolean;
  entries?: ApplicationEntry[];
  entriesCount?: number;
  applicationTags?: string[];
  applicationCountries?: Country[];
  applicationIndustries?: Interest[];
  applicationInterests?: Interest[];
  applicationSDGs?: SDG[];
  communityCover?: string;
  communityLogo?: string;
  isSDGsVisible?: boolean;
  isDevelopmentStageVisible?: boolean;
  isEvaluatorSetByCircle: boolean;
  language: string;
  trackUserAccess?: string | null;
  trackApplicationSave?: string | null;
  trackApplicationSubmission?: string | null;
};

export type ApplicationEvaluator = {
  userId: number;
  user: {
    name: string;
    photoUrl: string;
  };
};

export type ApplicationEntry = {
  id: number;
  applicationDefinitionId: number;
  formAnswers: FormQuestionAnswer[];
  project?: NewProject;
  applyDate?: string;
  deniedDate?: string;
  applicantId: number;
  status: ApplicationStatus;
  tags: string[];
  projectAnswers?: FormQuestionAnswer[];
  scores: ApplicationScore[];
  applicantEmail?: string;
  applicantName?: string;
  applicationDefinitionName?: string;
  applicantPhotoUrl?: string;
  averageScore?: number;
  comments?: Comment[];
  communityName?: string;
  communityLogoImage?: string;
  existingProjectId: number | null;
};

export type ApplicationScore = {
  userId: number;
  userName: string;
  userPhotoUrl: string;
  criteria: string;
  score: number;
};

export type Form = {
  id: number;
  name: string;
  description: string;
  communityId: number;
  creationDate: string;
  lastEditionDate: string;
  userCreatorId: number;
  questions: FormQuestion[];
};

export type QuestionRule = {
  id: number;
  conditionFormQuestionId: number;
  customValue: string | number;
  conditionLogic: number;
  formQuestionOptionId: number | null;
};

export enum FormQuestionConditionalLogicType {
  None = 0,
  AnyConditional = 1,
  AllConditionals = 2,
}

export enum FormQuestionConditionalLogic {
  EQUAL = 1,
  NOT_EQUAL = 2,
  GREATER_THAN = 3,
  LESS_THAN = 4,
  CONTAINS = 5,
  DOES_NOT_CONTAIN = 6,
  EMPTY = 7,
  NOT_EMPTY = 8,
}

export type FormQuestion = {
  id: number;
  title: string;
  question?: string;
  description: string;
  questionOrder: number;
  mandatoryAnswer: boolean;
  answerType: FormQuestionAnswerType;
  answerSubtype: string;
  allowMultipleAnswers: boolean;
  questionOptions?: FormQuestionOption[];
  isFilterable?: boolean;
  conditionalLogic?: boolean;
  conditionalLogicType?: FormQuestionConditionalLogicType;
  formQuestionConditionalLogicList?: QuestionRule[];
  privacyLevel?: number;
  privacyCirclesId?: number[];
  isNew?: boolean;
};

export type FormQuestionOption = {
  id: number;
  questionOption: string;
  questionOptionOrder: number;
  isNew?: boolean;
};

export type FormQuestionAnswer = {
  id: number;
  formId: number;
  formQuestionId: number;
  answer: string;
  optionAnswerOrder: number;
  optionRowAnswerOrder: number;
};

export interface AnswerRow {
  [key: string]: string;
}

export type ImageSizeType = '32x32' | '64x64' | '128x128' | '256x256' | '512x512' | '1024x1024';

export type LayoutType =
  | '1-column'
  | '2-columns'
  | '3-columns'
  | '3-columns-cover'
  | 'full-screen'
  | 'settings'
  | 'super-admin'
  | 'landing';

export interface CommunityTab {
  id: number;
  name: string;
  icon: string;
  requestIntro: boolean;
  requestFormId?: number;
  requestForm?: Form;
  communityId: number;
  formId: number;
  form: Form;
  iconFile?: File;
  entities: CommunityEntityTab[];
  canFollow: boolean;
  totalEntityCount: number;
  includeInLandingPage: boolean;
}

export interface CommunityFilter {
  answers: string[];
  question: string;
  numberOptions?: number[];
  minDate?: Date;
  maxDate?: Date;
  type?: string;
}

export enum CommunityFilterType {
  IS_ANY_OF = 0,
  IS_NONE_OF = 1,
}

export type Tag = {
  currentTagName: string;
  newTagName: string;
};

export enum TagType {
  RESOURCE = 'Resource',
  COMMUNITY_DISCUSSION = 'CommunityDiscussion',
  PROJECT_DISCUSSION = 'DISCUSSION',
  REQUEST = 'Request',
  USER = 'User',
}

export interface CommunityEntityTabFilters {
  filters: CommunityFilter[];
}

export interface CommunityProjectsFilters {
  countries: Country[];
  industries: ProjectCategory[];
  filters: CommunityFilter[];
  developmentStages: string[];
  tags: string[];
  sustainableDevelopmentGoals: SDG[];
  programs: Program[];
}

export interface CommunityMembersFilters {
  countries: Country[];
  industries: MembersCategory[];
  filters: CommunityFilter[];
  tags: string[];
  interests: RequestFilteringSkill[];
  marketExpretises: MarketExpertise[];
  companies: string[];
}

export interface CommunityRequestsFilters {
  countries: Country[];
  industries: ProjectCategory[];
  filters: CommunityFilter[];
  tags: string[];
  skillCategories: ProjectCategory[];
}

export interface ApplicationFilters {
  countries: Country[];
  industries: Interest[];
  filters: CommunityFilter[];
  tags: string[];
  interests: Interest[];
  sdgs: SDG[];
}

export interface TagsFilters {
  filters: CommunityFilter[];
  tags: string[];
}

export interface MarketExpertise {
  name: string;
  id: number;
  isSelected: boolean;
}
export interface CommunityFilterAnswerItem {
  filterType: CommunityFilterType;
  question: string;
  answers: string[];
  numberAnswers?: number[];
}

export interface CommunityEntityTabFilterAnswer {
  filters: CommunityFilterAnswerItem[];
}
export interface CommunityProjectsFilterAnswer {
  countryFilter: CommunityStaticFilter;
  industryFilter: CommunityStaticFilter;
  sdgFilter: CommunityStaticFilter;
  developmentStageFilter: CommunityStaticFilter;
  tagFilter: CommunityStaticFilter;
  programFilter: CommunityStaticFilter;
  filters: CommunityFilterAnswerItem[];
  orderBy?: number;
  concept?: string;
}

export interface CommunityStaticFilter {
  filterType: CommunityFilterType;
  values: number[] | string[];
}

export interface CommunityMembersFilterAnswer {
  countryFilter: CommunityStaticFilter;
  industryFilter: CommunityStaticFilter;
  tagFilter: CommunityStaticFilter;
  filters: CommunityFilterAnswerItem[];
  skillFilter: CommunityStaticFilter;
  marketExpertiseFilter: CommunityStaticFilter;
  orderBy?: number;
  concept?: string;
  companyFilter: CommunityStaticFilter;
}

export interface CommunityRequestsFilterAnswer {
  marketsExpertisesFilter: CommunityStaticFilter;
  requestIndustryFilter: CommunityStaticFilter;
  tagFilter: CommunityStaticFilter;
  filters: CommunityFilterAnswerItem[];
  skillFilter: CommunityStaticFilter;
}

export interface FilterAnswer {
  countryFilter: CommunityStaticFilter;
  industryFilter: CommunityStaticFilter;
  tagFilter: CommunityStaticFilter;
  filters: CommunityFilterAnswerItem[];
  interestsFilter: CommunityStaticFilter;
  sdgFilter: CommunityStaticFilter;
}

export interface TagsFilterAnswer {
  tagFilter: CommunityStaticFilter;
  filters: CommunityFilterAnswerItem[];
}

export interface OverviewProjectsFilterAnswer {
  filters: CommunityFilterAnswerItem[];
}
export interface CommunityEntityTab {
  id: number;
  countryId: number;
  logo: string;
  title: string;
  isFollowing: boolean;
  description: string;
  createdAt: string;
  lastModified: string;
}

export type TabEntityCommunityCircle = { communityCircleId: number };

export interface TabEntity {
  id: number;
  tabId: number;
  title: string;
  description: string;
  countryId: number;
  logo: string;
  coverImage: string;
  creatorUserId: number;
  isFollowing: boolean;
  createdAt: string;
  editedAt: null;
  form: Form;
  answers: FormQuestionAnswer[];
  tabEntityCommunityCircles: TabEntityCommunityCircle[];
  tabEntityCommunityUserCircleTags: string[];
}

export interface TabEntityCircle extends Circle {
  tabEntityId: number;
  name: string;
  isTeamCircle: boolean;
  isSpecialCircle: boolean;
}

export interface TabEntityComment extends Comment {
  tabEntityId: number;
  type: CommentType;
}

export interface GetTabEntityComment {
  list: TabEntityComment[];
  communityId: number;
  tabEntityId: number;
}

export interface TabEntityUserList {
  isMember: boolean;
  canInviteUser: boolean;
  isAdmin: boolean;
  pendingByUsers: any[];
  users: TabEntityUserListUsers;
  pendingApplyToTeam: any[];
}

export interface TabEntityUserListUsers {
  count: number;
  list: TabEntityUserListItemList[];
}

export interface ProjectRequestFeedback {
  advisorComment: string | null;
  advisorFeedbackDatetime: string | null;
  advisorFirstName: string;
  advisorId: number;
  advisorLastName: string;
  advisorNote: string | null;
  advisorOccupation: string;
  advisorPhotoUrl: string;
  advisorRating: number | null;
  advisorStatus: number;
  assignedDateTime: string;
  entrepreneurComment: string | null;
  entrepreneurFeedbackDatetime: string | null;
  entrepreneurRating: number | null;
  eventDateTimeStart: string;
  eventId: string;
  invitedDateTime: string;
  requestId: number;
}

export interface TabEntityUserListItemList {
  tabEntityCircleId: number;
  isPendingByUser: boolean;
  isPendingByTeam: boolean;
  requestedTabEntityCircleId: null;
  id: number;
  firstName: string;
  lastName: string;
  mail: string;
  photo: string;
  occupation: string;
  biography: string;
  commentCount: number;
  likeCount: number;
  followedUsersCount: number;
  activationCode: null;
  activationCodeSent: boolean | null;
  isProfileWizardDone: boolean;
  countryId: number;
  country: null;
  city: string;
  isBanned: boolean;
  isActive: boolean;
  invitationUrl: null;
  language: string;
  registrationDate: string;
  lastLoginDate: string;
  notificationEnabled: boolean;
  primaryCommunityId: number;
  hasPendingRequests: boolean | null;
  userInterests: null;
  userSkills: null;
  userSocial: null;
  userMarketsExpertises: null;
  userSustainableDevelopmentsGoals: null;
  termsAndConditionsVersion: number;
  photoUrl: string;
  facebookUrl: null;
  twitterUrl: null;
  googleUrl: null;
  linkedInUrl: null | string;
  isFollowedByMe: boolean;
}
export interface CommunityEntityTabForm extends Partial<TabEntity> {
  logoFile?: File;
  coverImageFile?: File;
}
export type CommunityState = {
  list: Community[];
  selectedCommunity: Community | null;
};

export interface DataObject {
  name: string;
  [key: string]: any;
}

export type Replacement = {
  target: string;
  replacement: string;
};

export type Program = {
  id: number;
  name: string;
};
export type PendingRequest = {
  id: number;
  name: string;
  description: string;
  imageUrl?: null;
  resources?: string[] | null;
  userId: number;
  userFirstName?: null;
  userLastName?: null;
  userMail?: null;
  requestStatus: number;
  tags?: string[] | null;
  matchMakingScore: number;
  projectId: number;
  projectName?: null;
  projectLogoUrl?: null;
  commentCount: number;
  recommended: boolean;
  requestType: number;
  endDate: string;
};
